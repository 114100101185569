import React from "react";
// React-table library requires to import css as well:
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import { getConsulenteId } from "../../../utils/storage";

import moment from "moment";
import "moment/locale/it";
import ModalGiorniChiusura from "./ModalGiorniChiusura";

import theme from "../../../theme";

const styles = {
  container: {
    padding: "0px 0px 20px 0px",
  },
};

export default class GiorniChiusuraEsercizio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalApertura: false,
    };
  }
  deleteelement = (cellInfo) => {
    let array1 = this.props.giorniChiusura.filter((a) => a.apertura === false);
    array1.splice(cellInfo.index, 1);
    // alert(JSON.stringify(        array1.splice(cellInfo.index, 1)))
    let array = this.props.giorniChiusura.filter((a) => a.apertura === true);

    let concat = array1.concat(array);

    this.props.updateParentState(concat);
  };

  deleteelementeventi = (cellInfo) => {
    let array = this.props.giorniChiusura.filter((a) => a.apertura === true);

    let array1 = this.props.giorniChiusura.filter((a) => a.apertura === false);
    array.splice(cellInfo.index, 1);
    let concat = array.concat(array1);
    this.props.updateParentState(concat);
  };

  completeModal = () => {
    this.setState({
      modal: false,
    });
  };
  openModal = () => {
    this.setState({
      modal: true,
    });
  };
  closeModal = () => {
    this.setState({
      modal: false,
    });
  };
  completeModalApertura = () => {
    this.setState({
      modalApertura: false,
    });
  };
  openModalApertura = () => {
    this.setState({
      modalApertura: true,
    });
  };
  closeModalApertura = () => {
    this.setState({
      modalApertura: false,
    });
  };
  render() {
    let isUserConsulente =
      getConsulenteId() !== null && getConsulenteId() !== "null";
    const dateFormat = "DD-MM-YYYY";

    const columns = [
      {
        Header: "Tipo",
        id: "tipo",
        accessor: (d) => {
          switch (d.tipo) {
            case "RICORRENTE":
              return "Ricorrente";
            case "PERIODO":
              return "Periodo";
            case "SPECIFICO":
              return "Giorno specifico";

            default:
              return "-";
          }
        },
        sortable: false,
        filterable: false,
        minWidth: 120,
      },
      {
        Header: "Data inizio",
        id: "dataInizio",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataInizio ? moment(d.dataInizio).format(dateFormat) : "-",
        minWidth: 120,
      },
      {
        Header: "Data fine",
        id: "dataFine",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataFine ? moment(d.dataFine).format(dateFormat) : "-",
        minWidth: 120,
      },
      {
        Header: "Data Specifica",
        id: "dataSpecifica",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataSpecifica ? moment(d.dataSpecifica).format(dateFormat) : "-",
        minWidth: 120,
      },
      {
        Header: "Orario Inizio",
        id: "orarioInizio",
        sortable: false,
        filterable: false,
        accessor: (d) => (d.orarioInizio ? d.orarioInizio : "-"),
        minWidth: 120,
      },
      {
        Header: "Orario Fine",
        id: "orarioFine",
        sortable: false,
        filterable: false,
        accessor: (d) => (d.orarioFine ? d.orarioFine : "-"),
        minWidth: 120,
      },
      {
        Header: "Giorno",
        id: "giorno",
        sortable: false,
        filterable: false,
        accessor: (d) => {
          switch (d.giorno) {
            case "MONDAY":
              return "Lunedì";
            case "TUESDAY":
              return "Martedì";
            case "WEDNESDAY":
              return "Mercoledì";
            case "THURSDAY":
              return "Giovedì";
            case "FRIDAY":
              return "Venerdì";
            case "SATURDAY":
              return "Sabato";
            case "SUNDAY":
              return "Domenica";

            default:
              return "-";
          }
        },
        width: 120,
      },
      {
        Header: "Intervallo",
        id: "intervallo mensile",
        sortable: false,
        filterable: false,
        accessor: (d) => {
          switch (d.ripetizioneSettimanale) {
            case 1:
              return "Prima settimana";
            case 2:
              return "Seconda settimana";
            case 3:
              return "Terza settimana";
            case 4:
              return "Quarta settimana";
            case 5:
              return "Ultima settimana";

            default:
              return "-";
          }
        },
        minWidth: 120,
      },
      {
        Header: "Elimina",
        id: "elimina",
        sortable: false,
        filterable: false,
        minWidth: 120,
        accessor: (row) => row,

        Cell: (props) => (
          <Button
            type="button"
            onClick={() => this.deleteelement(props)}
            disabled={this.props.disabledButtons}
            variant="contained"
            size="medium"
            style={{
              color: this.props.disabledButtons
                ? theme.palette.text.main
                : this.props.theme.palette.text.main,
              margin: "10px",
              backgroundColor: this.props.disabledButtons
                ? theme.palette.disabled.main
                : this.props.theme.palette.primary.main,
            }}
          >
            ELIMINA
          </Button>
        ),
      },
    ];
    const columnsApertura = [
      {
        Header: "Tipo",
        id: "tipo",
        accessor: (d) => {
          switch (d.tipo) {
            case "RICORRENTE":
              return "Ricorrente";
            case "PERIODO":
              return "Periodo";
            case "SPECIFICO":
              return "Giorno specifico";

            default:
              return "-";
          }
        },
        sortable: false,
        filterable: false,
        minWidth: 120,
      },
      {
        Header: "Data inizio",
        id: "dataInizio",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataInizio ? moment(d.dataInizio).format(dateFormat) : "-",
        minWidth: 120,
      },
      {
        Header: "Data fine",
        id: "dataFine",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataFine ? moment(d.dataFine).format(dateFormat) : "-",
        minWidth: 120,
      },
      {
        Header: "Data Specifica",
        id: "dataSpecifica",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataSpecifica ? moment(d.dataSpecifica).format(dateFormat) : "-",
        minWidth: 120,
      },
      {
        Header: "Orario Inizio",
        id: "orarioInizio",
        sortable: false,
        filterable: false,
        accessor: (d) => (d.orarioInizio ? d.orarioInizio : "-"),
        minWidth: 120,
      },
      {
        Header: "Orario Fine",
        id: "orarioFine",
        sortable: false,
        filterable: false,
        accessor: (d) => (d.orarioFine ? d.orarioFine : "-"),
        minWidth: 120,
      },
      {
        Header: "Giorno",
        id: "giorno",
        sortable: false,
        filterable: false,
        accessor: (d) => {
          switch (d.giorno) {
            case "MONDAY":
              return "Lunedì";
            case "TUESDAY":
              return "Martedì";
            case "WEDNESDAY":
              return "Mercoledì";
            case "THURSDAY":
              return "Giovedì";
            case "FRIDAY":
              return "Venerdì";
            case "SATURDAY":
              return "Sabato";
            case "SUNDAY":
              return "Domenica";

            default:
              return "-";
          }
        },
        width: 120,
      },
      {
        Header: "Intervallo",
        id: "intervallo mensile",
        sortable: false,
        filterable: false,
        accessor: (d) => {
          switch (d.ripetizioneSettimanale) {
            case 1:
              return "Prima settimana";
            case 2:
              return "Seconda settimana";
            case 3:
              return "Terza settimana";
            case 4:
              return "Quarta settimana";
            case 5:
              return "Ultima settimana";

            default:
              return "-";
          }
        },
        minWidth: 120,
      },
      {
        Header: "Elimina",
        id: "elimina",
        sortable: false,
        filterable: false,
        minWidth: 120,
        accessor: (row) => row,

        Cell: (props) => (
          <Button
            type="button"
            onClick={() => this.deleteelementeventi(props)}
            disabled={this.props.disabledButtons}
            variant="contained"
            size="medium"
            style={{
              color: this.props.disabledButtons
                ? theme.palette.text.disabled
                : this.props.theme.palette.text.main,
              margin: "10px",
              backgroundColor: this.props.disabledButtons
                ? theme.palette.disabled.main
                : this.props.theme.palette.primary.main,
            }}
          >
            ELIMINA
          </Button>
        ),
      },
    ];

    return (
      <Container style={styles.container}>
        <ModalGiorniChiusura
          theme={this.props.theme}
          esercizioId={this.props.esercizioId}
          handleComplete={this.completeModal}
          handleClose={this.closeModal}
          giorniChiusura={this.props.giorniChiusura}
          modal={this.state.modal}
          isConsulente={isUserConsulente}
          updateParentState={this.props.updateParentState}
          apertura={false}
        />
        <ModalGiorniChiusura
          theme={this.props.theme}
          esercizioId={this.props.esercizioId}
          handleComplete={this.completeModalApertura}
          handleClose={this.closeModalApertura}
          giorniChiusura={this.props.giorniChiusura}
          modal={this.state.modalApertura}
          isConsulente={isUserConsulente}
          updateParentState={this.props.updateParentState}
          apertura={true}
        />

        <Typography
          variant="h6"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          Gestione giorni chiusura
        </Typography>
        <Button
          type="button"
          disabled={this.props.disabledButtons}
          onClick={() => this.openModal()}
          variant="contained"
          size="medium"
          style={{
            color: this.props.disabledButtons
              ? theme.palette.text.disabled
              : this.props.theme.palette.text.main,
            margin: "10px",
            backgroundColor: this.props.disabledButtons
              ? theme.palette.disabled.main
              : this.props.theme.palette.primary.main,
          }}
        >
          Aggiungi giorno chiusura
        </Button>
        <ReactTable
          filterable={true}
          resizable={true}
          showPageSizeOptions={true}
          showPageJump={true}
          defaultPageSize={10}
          //pages={this.state.pages}
          data={this.props.giorniChiusura.filter((a) => a.apertura === false)}
          columns={columns}
          //manual // informs React Table that you'll be handling sorting and pagination server-side
          //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
          previousText="Precedente"
          nextText="Successivo"
          noDataText="Nessun record"
          pageText="Pagina"
          ofText="di"
          rowsText="righe"
          pageJumpText="Vai a pagina"
          rowsSelectorText="righe per pagina"
        />
        <Typography
          variant="h6"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          Gestione giorni apertura straordinaria
        </Typography>
        <Button
          type="button"
          disabled={this.props.disabledButtons}
          onClick={() => this.openModalApertura()}
          variant="contained"
          size="medium"
          style={{
            color: this.props.disabledButtons
              ? theme.palette.text.disabled
              : this.props.theme.palette.text.main,
            margin: "10px",
            backgroundColor: this.props.disabledButtons
              ? theme.palette.disabled.main
              : this.props.theme.palette.primary.main,
          }}
        >
          Aggiungi apertura straordinaria
        </Button>
        <ReactTable
          filterable={true}
          resizable={true}
          showPageSizeOptions={true}
          showPageJump={true}
          defaultPageSize={10}
          //pages={this.state.pages}
          data={this.props.giorniChiusura.filter((a) => a.apertura === true)}
          columns={columnsApertura}
          //manual // informs React Table that you'll be handling sorting and pagination server-side
          //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
          previousText="Precedente"
          nextText="Successivo"
          noDataText="Nessun record"
          pageText="Pagina"
          ofText="di"
          rowsText="righe"
          pageJumpText="Vai a pagina"
          rowsSelectorText="righe per pagina"
        />
      </Container>
    );
  }
}

GiorniChiusuraEsercizio.propTypes = {
  giorniChiusura: PropTypes.array.isRequired,
  updateParentState: PropTypes.func.isRequired,
  esercizioId: PropTypes.number,
  disabledButtons: PropTypes.bool.isRequired,
};
