import { Container, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { clearToken } from "../../../utils/storage";
import LoghiTemplate from "./LoghiTemplate";
import ActionButton from "../../../components/ActionButton";
import { ChromePicker } from "react-color";
import ErrorDialog from "../../../components/ErrorDialog";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  createBrand,
  getLogoByPath,
  getLogoLeftByPath,
  updateBrand,
} from "../../../utils/api/brand_api";
import LogoHeaderTemplate from "./LogoHeaderTemplate";
import BrandNameInput from "./BrandNameInput";
import BrandUrlField from "./BrandUrlField";
import ColorPickerToggle from "./ColorPickerToggle";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingLeft: "4px",
    paddingRight: "4px",
    minHeight: "86vh",
  },
  buttonContainer: {
    margin: "38px 8px 8px 8px",
  },
  paper: {
    width: 890,
    padding: "12px",
    boxShadow: theme.shadows[5],
  },
  text: {
    margin: "5px 0px ",
  },
  loghiWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  halfWidth: { flex: 1 },
}));

export default function ModificaTemplate(props) {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const isEditMode = !!location.state;

  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState({
    nome: isEditMode ? location.state.brandName : "",
    path: isEditMode ? location.state.path : "",
    logo: "",
    logoLeft: "",
    primaryColor: isEditMode
      ? location.state.primaryColor
      : theme.palette.primary.main,
    secondaryColor: isEditMode
      ? location.state.secondaryColor
      : theme.palette.secondary.main,
    textColor: isEditMode
      ? location.state.textColor
      : theme.palette.text.primary,
  });
  const [brandName, setBrandName] = useState(templateData.nome);
  const [isUrlEditable, setIsUrlEditable] = useState(false);
  const [loghiTemplate, setLoghiTemplate] = useState([templateData.logo]);
  const [logoLeftUrl, setLogoLeftUrl] = useState("");
  const [changesNotSaved, setChangesNotSaved] = useState(false);
  const [selectedColor, setSelectedColor] = useState({
    primary: templateData.primaryColor,
    secondary: templateData.secondaryColor,
    text: templateData.textColor,
  });
  const [visiblePicker, setVisiblePicker] = useState("primary");
  const [errorDialog, setErrorDialog] = useState({
    isVisible: false,
    title: null,
    message: "",
  });

  useEffect(() => {
    let isMounted = true;
    if (location.state?.path) {
      setLoading(true);

      const fetchLogos = async () => {
        try {
          const response = await getLogoByPath(location.state.path);
          if (isMounted) {
            const blob = await response.blob();
            const logoUrl = URL.createObjectURL(blob);
            setLoghiTemplate([logoUrl]);
            setTemplateData((prev) => ({ ...prev, logo: blob }));
          }
          const responseLeft = await getLogoLeftByPath(location.state.path);
          if (isMounted) {
            const blobLeft = await responseLeft.blob();
            const logoLeftUrl = URL.createObjectURL(blobLeft);
            setLogoLeftUrl(logoLeftUrl);
            setTemplateData((prev) => ({ ...prev, logoLeft: blobLeft }));
          }
        } catch (error) {
          if (error.status === 403) {
            handleInvalidToken();
          } else if (error.status === 404) {
            console.log("Logo header non trovato.");
          }
        } finally {
          if (isMounted) setLoading(false);
        }
      };

      fetchLogos();
    } else {
      console.error("Path mancante!");
    }
    return () => {
      isMounted = false;
    };
  }, [location.state?.path]);

  const handleBrandNameChange = (e) => {
    const inputValue = e.target.value;
    setBrandName(inputValue);
    setChangesNotSaved(true);
    if (props.mode !== "Update") {
      const restrictedInput = inputValue
        .replace(/\s+/g, "-")
        .replace(/[^a-zA-Z0-9-]/g, "")
        .toLowerCase();
      setTemplateData((prev) => ({ ...prev, path: restrictedInput }));
    }
  };

  const handleEditUrlClick = () => {
    setIsUrlEditable(true);
  };

  const handlePathChange = (e) => {
    const inputValue = e.target.value;
    const restrictedInput = inputValue
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9-]/g, "")
      .toLowerCase();
    setTemplateData((prev) => ({ ...prev, path: restrictedInput }));
    setChangesNotSaved(true);
  };

  const handleColorChange = (colorType, color) => {
    if (color?.hex) {
      setSelectedColor((prev) => ({ ...prev, [colorType]: color.hex }));
      setChangesNotSaved(true);
    }
  };

  const handleChromePickerVisibility = (picker) => {
    setVisiblePicker(picker);
  };

  const uploadImage = (file, event) => {
    if (
      (file.type === "image/png" || file.type === "image/jpeg") &&
      Math.floor(file.size / 1000) < 150
    ) {
      const blob = new Blob([file], { type: "image/png" });
      const url = URL.createObjectURL(blob);
      const loghiTemplateToUse = [...loghiTemplate];
      loghiTemplateToUse[0] = url;
      setLoghiTemplate(loghiTemplateToUse);
      setTemplateData((prev) => ({
        ...prev,
        logo: file,
      }));
      setChangesNotSaved(true);
    } else {
      openErrorDialog(
        "Attenzione, è possibile caricare solo immagini in formato PNG di dimensione inferiore a 150kb"
      );
    }
  };

  const uploadLogoLeft = (file) => {
    if (!file) {
      openErrorDialog("Nessun file selezionato.");
      return;
    }
    if (
      (file.type === "image/png" || file.type === "image/jpeg") &&
      Math.floor(file.size / 1000) < 150
    ) {
      const blob = new Blob([file], { type: "image/png" });
      const url = URL.createObjectURL(blob);
      setTemplateData((prev) => ({
        ...prev,
        logoLeft: blob,
      }));
      setLogoLeftUrl(url);
      setChangesNotSaved(true);
    } else {
      openErrorDialog(
        "Attenzione, è possibile caricare solo immagini in formato PNG di dimensione inferiore a 150kb"
      );
    }
  };

  const openErrorDialog = (message, title = null) => {
    setErrorDialog({ isVisible: true, title, message });
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      isVisible: false,
      title: null,
      message: "",
    });
  };

  const handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    openErrorDialog(errorMessage);
    clearToken();
    const { path } = props.theme;
    window.setTimeout(() => {
      window.location.href = `/${path}`;
    }, 4000);
  };

  const createOrUpdateBrand = () => {
    const formData = new FormData();

    if (isEditMode) {
      if (!brandName.trim() || !templateData.logo || !templateData.logoLeft) {
        openErrorDialog(
          "Attenzione! Occorre inserire il nome del brand ed entrambi i loghi per procedere."
        );
        return;
      }
      formData.append("id", location.state.id);
      formData.append("name", brandName);
      formData.append("primaryColor", selectedColor.primary);
      formData.append("secondaryColor", selectedColor.secondary);
      formData.append("textColor", selectedColor.text);
      if (typeof templateData.logo === "object") {
        formData.append("logo", templateData.logo);
      }
      if (typeof templateData.logoLeft === "object")
        formData.append("logoLeft", templateData.logoLeft);

      updateBrand(formData)
        .then((_response) => {
          console.log("Brand aggiornato con successo!");
          history.goBack();
        })
        .catch((error) => openErrorDialog(error.message, "Attenzione"));
    } else {
      if (
        !brandName.trim() ||
        !templateData.path.trim() ||
        !templateData.logo ||
        !templateData.logoLeft
      ) {
        openErrorDialog("Attenzione! Nome del brand, path o loghi mancanti.");
        return;
      }
      formData.append("nome", brandName);
      formData.append("path", templateData.path);
      formData.append("primaryColor", selectedColor.primary);
      formData.append("secondaryColor", selectedColor.secondary);
      formData.append("textColor", selectedColor.text);
      formData.append("logo", templateData.logo);
      formData.append("loghi", []);
      if (typeof templateData.logoLeft === "object")
        formData.append("logoLeft", templateData.logoLeft);

      createBrand(formData)
        .then((_response) => {
          console.log("Il nuovo brand è stato registrato con successo.");
          history.goBack();
        })
        .catch((error) => {
          if (error.status === 409) {
            openErrorDialog(error.message, "Attenzione");
          } else {
            openErrorDialog("Attenzione, creazione del brand fallita");
          }
        });
    }
  };

  const handleBackClick = () => {
    if (changesNotSaved) {
      const confirmLeave = window.confirm(
        "Hai delle modifiche non salvate. Sei sicuro di voler uscire?"
      );
      if (confirmLeave) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      {loading ? (
        <SpinnerComponent size={24} />
      ) : (
        <>
          <Typography variant="h4" className={classes.texts}>
            {`Loghi del brand ${templateData.nome}`}
          </Typography>
          <BrandNameInput
            isEditMode={isEditMode}
            brandName={brandName}
            handleBrandNameChange={handleBrandNameChange}
          />
          <BrandUrlField
            setIsUrlEditable={() => {
              setIsUrlEditable(false);
            }}
            url={templateData.path}
            onUrlChange={handlePathChange}
            isUrlEditable={isUrlEditable}
            handleEditUrlClick={handleEditUrlClick}
            isEditMode={isEditMode}
          />
          <div>
            <Typography variant="h6" className={classes.text}>
              Logo del brand
            </Typography>
            <Typography className={classes.text}>
              Qui è possibile personalizzare il logo. La dimensione massima
              consentita è 150KB.
            </Typography>
            <Typography className={classes.text}>
              I file caricati devono essere in formato JPEG (o JPG) o PNG.
            </Typography>
            <Typography className={classes.text}>
              Il seguente logo sarà utilizzato all'interno del template che si
              sta modificando.
            </Typography>
          </div>
          <div className={classes.loghiWrapper}>
            <div className={classes.halfWidth}>
              <LoghiTemplate loghiIds={loghiTemplate} onSubmit={uploadImage} />
            </div>
            <div className={classes.halfWidth}>
              <LogoHeaderTemplate
                logoHeader={logoLeftUrl}
                uploadLogoHeader={uploadLogoLeft}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Paper className={classes.paper}>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ flexDirection: "column", display: "flex" }}
              >
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", marginTop: 15 }}
                >
                  Colori del brand
                </Typography>
                <Typography style={{ marginBottom: 30, marginTop: 15 }}>
                  Seleziona i due colori di riferimento della tua attività
                </Typography>
                <ColorPickerToggle
                  selectedColor={selectedColor}
                  visiblePicker={visiblePicker}
                  onColorChange={handleColorChange}
                  onToggleVisibility={handleChromePickerVisibility}
                />
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  marginTop: 50,
                  marginBottom: 30,
                }}
              >
                <Typography style={{ marginBottom: 30 }}>
                  Seleziona il colore del testo
                </Typography>
                <ChromePicker
                  color={selectedColor.text}
                  onChangeComplete={(color) => handleColorChange("text", color)}
                  disableAlpha
                />
              </Grid>
            </Paper>
          </div>
          <Grid
            container
            justify="center"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{}}>
              <ActionButton
                label="Indietro"
                secondaryButton
                onClick={handleBackClick}
                grayVersion={false}
                disabled={false}
              />
              <ActionButton
                label={isEditMode ? "Salva modifiche" : "Crea brand"}
                disabled={loading}
                onClick={createOrUpdateBrand}
                grayVersion={false}
              />
            </Grid>
          </Grid>
        </>
      )}
      <ErrorDialog
        open={errorDialog.isVisible}
        title={errorDialog.title}
        message={errorDialog.message}
        onCloseButtonClicked={closeErrorDialog}
      />
    </Container>
  );
}

ModificaTemplate.propTypes = {
  mode: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};
