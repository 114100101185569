import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";

import ResetPswSuccessPage from "./scenes/resetpsw/ResetPswSuccessPage";
import AppMainComponent from "./AppMainComponent";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import {
  getBrandByPath,
  getLogoByPath,
  getLogoLeftByPath,
} from "./utils/api/brand_api";
import "./App.css";
import Footer from "./components/Footer";
import Favicon from "react-favicon";
import logo from "../src/logo.png";

export default function App() {
  const [theme, setTheme] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let url = window.location.pathname.split("/")[1];
    getBrandByPath(url)
      .then((response) => {
        getLogoByPath(url)
          .then((response2) => {
            getLogoLeftByPath(url)
              .then((response3) => {
                setTheme({
                  primaryColor: response.primaryColor,
                  secondaryColor: response.secondaryColor,
                  textColor: response.textColor,
                  logo: response2,
                  logoLeft: response3,
                  nomeBrand: response.nome,
                  path: url === "" ? "bsfood" : response.path,
                });
                if (url !== "") {
                  document.title = response.nome + " Control Panel";
                } else {
                  document.title = "BS-FOOD Control Panel";
                }
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                console.error(error);
                setLoading(false);
              });
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setLoading(false);
      });
  }, []);

  const muiTheme = createMuiTheme({
    // ... definisci qui le personalizzazioni del tema usando i dati ottenuti
    palette: {
      primary: {
        main: theme?.primaryColor || "#e17414",
      },
      secondary: {
        main: theme?.secondaryColor || "#ff0000",
      },
      text: {
        main: theme?.textColor || "#ffffff",
      },

      // ... altre personalizzazioni del tema
      warning: {
        main: "#fef8a0",
      },
      disabled: {
        main: "#e0e0e0", //light gray
      },
    },
    logo: theme?.logo || "",
    logoLeft: theme?.logoLeft || "",
    nomeBrand: theme?.nomeBrand || "BS-FOOD",
    path:
      window.location.pathname.split("/")[1] === ""
        ? "bsfood"
        : window.location.pathname.split("/")[1],
    // ... altre opzioni del tema (magari immagini)
  });

  if (!theme) {
    // Puoi decidere come gestire il caso in cui i dati del tema non siano ancora disponibili
    //return null; // o un componente di caricamento, un messaggio, ecc.
  }

  return loading ? (
    <div className="loadingBrand">
      <div className="spinnerBrand"></div>
      <p>Caricamento...</p>
    </div>
  ) : (
    <MuiThemeProvider theme={muiTheme}>
      <Favicon url={muiTheme.logoLeft.url || logo} />
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route
              path="/reset_password_success"
              component={ResetPswSuccessPage}
            />
            <Route path="/" render={() => <AppMainComponent />} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}
