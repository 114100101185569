import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Search, Settings } from "@material-ui/icons";
import SpinnerComponent from "../../../components/SpinnerComponent";
import NewRecordButton from "../components/NewRecordButton";
import { getBrandCount, getBrands } from "../../../utils/api/brand_api";
import { clearToken } from "../../../utils/storage";
import ErrorDialog from "../../../components/ErrorDialog";
import BasicTable from "../../../components/BasicTable";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  newBrandBtn: {
    display: "flex",
    justifyContent: "end",
  },
  column: { display: "flex", alignItems: "center", justifyContent: "center" },
  colorBox: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
}));

const BrandTable = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [errorDialog, setErrorDialog] = useState({
    isVisible: false,
    title: null,
    message: "",
  });
  const [paginationConfig, setPaginationConfig] = useState({
    pageSize: 10,
    loadedPage: 0,
    totalPages: 1,
  });
  const [filter, setFilter] = useState({
    brandId: "",
    brandName: "",
  });

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getBrandCount()
      .then((countResponse) => {
        return getBrands(
          paginationConfig.loadedPage,
          paginationConfig.pageSize,
          "id"
        ).then((response) => {
          if (isMounted) {
            setBrands(response);
            setPaginationConfig((prev) => ({
              ...prev,
              totalPages:
                countResponse !== 0
                  ? Math.ceil(countResponse / prev.pageSize)
                  : 1,
            }));
          }
        });
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleClick = (row) => {
    history.push({
      pathname: `/amministrazione/edit_template/${row.id}`,
      state: {
        id: row.id,
        brandName: row.nome,
        path: row.path || "",
        logo: row.logo,
        primaryColor: row.primaryColor || theme.palette.primary.main,
        secondaryColor: row.secondaryColor || theme.palette.secondary.main,
        textColor: row.textColor || theme.palette.text.primary,
      },
    });
  };

  const renderColorBox = (color, defaultColor) => (
    <Box
      title={color || defaultColor}
      className={classes.colorBox}
      bgcolor={color || defaultColor}
      border="1px solid rgba(0, 0, 0, 0.2)"
    />
  );

  const openErrorDialog = (message) => {
    setErrorDialog({ isVisible: true, title: null, message });
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      isVisible: false,
      title: null,
      message: "",
    });
  };

  const handleError = (error) => {
    if (error.status === 403) {
      handleInvalidToken();
    } else {
      openErrorDialog(error.message);
    }
  };

  const handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    openErrorDialog(errorMessage);
    clearToken();
    const { path } = theme;
    window.setTimeout(() => {
      window.location.href = `/${path}`;
    }, 4000);
  };

  const fetchBrandsForResearch = (loadedPage) => {
    const { brandId, brandName } = filter;
    const { pageSize } = paginationConfig;

    getBrandCount(brandId, brandName)
      .then((countResponse) => {
        return getBrands(loadedPage, pageSize, "id", brandId, brandName).then(
          (response) => {
            setBrands(response);
            updatePaginationConfig(countResponse);
          }
        );
      })
      .catch(handleError);
  };

  const updatePaginationConfig = (countResponse) => {
    setPaginationConfig((prev) => ({
      ...prev,
      loadedPage: 0,
      totalPages:
        countResponse !== 0 ? Math.ceil(countResponse / prev.pageSize) : 1,
    }));
  };

  const generateFilterColumn = ({ id, placeholder, filterValue, onChange }) => {
    return {
      Header: placeholder,
      id: id,
      accessor: (d) => d[id],
      filterable: true,
      minWidth: 120,
      className: classes.column,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => fetchBrandsForResearch(0)}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  const columns = [
    generateFilterColumn({
      id: "id",
      placeholder: "ID",
      filterValue: filter.brandId,
      onChange: (value) => {
        if (/^\d*$/.test(value)) {
          setFilter((prev) => ({
            ...prev,
            brandId: value,
          }));
        }
      },
    }),
    generateFilterColumn({
      id: "nome",
      placeholder: "Brand",
      filterValue: filter.brandName,
      onChange: (value) => {
        setFilter((prev) => ({
          ...prev,
          brandName: value,
        }));
      },
    }),
    {
      Header: "Colore primario",
      accessor: "primaryColor",
      filterable: false,
      Cell: ({ value }) => renderColorBox(value, theme.palette.primary.main),
      className: classes.column,
    },
    {
      Header: "Colore secondario",
      accessor: "secondaryColor",
      filterable: false,
      Cell: ({ value }) => renderColorBox(value, theme.palette.secondary.main),
      className: classes.column,
    },
    {
      Header: "Colore testo",
      accessor: "textColor",
      filterable: false,
      Cell: ({ value }) => renderColorBox(value, theme.palette.text.primary),
      className: classes.column,
    },
    {
      Header: "Modifica template",
      id: "modifica",
      filterable: false,
      accessor: (row) => (
        <IconButton
          onClick={() => handleClick(row)}
          style={{ color: theme.palette.primary.main }}
        >
          <Settings />
        </IconButton>
      ),
    },
  ];

  const fetchBrandsForPageChange = (loadedPage) => {
    const { brandId, brandName } = filter;
    getBrands(loadedPage, paginationConfig.pageSize, "id", brandId, brandName)
      .then((response) => setBrands(response))
      .catch(handleError);
  };

  const onPageChange = (pageIndex) => {
    setPaginationConfig((prev) => ({ ...prev, loadedPage: pageIndex }));
    fetchBrandsForPageChange(pageIndex);
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <SpinnerComponent size={24} />
      ) : (
        <div className={classes.contentWrapper}>
          <div className={classes.newBrandBtn}>
            <NewRecordButton label="Crea nuovo brand" disabled={false} />
          </div>
          {/* <BrandList
            itemList={BrandsData}
            handleEditClick={(id) => handleEditClick(id)}
          /> */}
          <BasicTable
            data={brands}
            columns={columns}
            filterable={false}
            resizable={true}
            sortable={false}
            defaultPageSize={paginationConfig.pageSize}
            page={paginationConfig.loadedPage}
            pages={paginationConfig.totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
      <ErrorDialog
        open={errorDialog.isVisible}
        title={errorDialog.title}
        message={errorDialog.message}
        onCloseButtonClicked={closeErrorDialog}
      />
    </div>
  );
};

export default BrandTable;
