import { retrieveToken } from "../storage";
import { wrapFetch, ApiError, wrapFetchWithDetailedErrors } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getBrands = (page, size, sortby, brandId, brandNome) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/brand";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC`;

  if (brandId) {
    query += `&id.equals=${brandId}`;
  }
  if (brandNome) {
    query += `&nome.contains=${brandNome}`;
  }

  let URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
    }),
    errorFunction
  );
};

export const getBrandCount = (brandId, brandNome) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/brand/count";
  let query = `?`;

  if (brandId) {
    query += `&id.equals=${brandId}`;
  }
  if (brandNome) {
    query += `&nome.contains=${brandNome}`;
  }

  let URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
    }),
    errorFunction
  );
};

export const getBrand = (brandId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = `/api/brand/${brandId}`;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message =
        "Caricamento dati fallito: nessun brand corrispondente all'ID indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
    }),
    errorFunction
  );
};

export const getBrandByPath = (path) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/brand/by-path";
  const query = `?path=${path}`;
  const URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message =
        "Caricamento dati fallito: nessun brand corrispondente al path indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
    }),
    errorFunction
  );
};

export const getLogoByPath = (path) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/brand/by-path/logo";
  const query = `?path=${path}`;
  const URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message =
        "Caricamento dati fallito: logo non trovato per brand con path indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
    }),
    errorFunction
  );
};

export const getLogoLeftByPath = (path) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/brand/by-path/logo-left";
  const query = `?path=${path}`;
  const URL = BASE_URL + PATH + query;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message =
        "Caricamento dati fallito: logo non trovato per brand con path indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
    }),
    errorFunction
  );
};

export const createBrand = (formData) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/brand";
  const URL = BASE_URL + PATH;

  const errorFunction = (status, serverMessage = null) => {
    let message = serverMessage;
    if (status === 403) {
      message =
        message ||
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message =
        message || "Registrazione del brand non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        message ||
        "Registrazione del brand non riuscita: esiste già un brand registrato con gli stessi dati.";
    }
    return new ApiError(status, message);
  };
  return wrapFetchWithDetailedErrors(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        // "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      credentials: "include",
      body: formData,
    }),
    errorFunction
  );
};

export const updateBrand = (formData) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = `/api/brand/style/`;
  const URL = BASE_URL + PATH;

  const errorFunction = (status, serverMessage = null) => {
    let message = serverMessage;
    if (status === 403) {
      message =
        message ||
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message =
        message || "Salvataggio non riuscito: i dati non sono corretti.";
    } else if (status === 409) {
      message = message || '"Attenzione, esiste già un brand con questo nome"';
    }
    return new ApiError(status, message);
  };
  return wrapFetchWithDetailedErrors(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        // "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      credentials: "include",
      body: formData,
    }),
    errorFunction
  );
};

export const updateLoghiBrand = (brandId, nomeBrand, loghiIds) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/brand";
  const URL = BASE_URL + PATH;
  const requestBody = {
    id: brandId,
    nome: nomeBrand,
    loghi: loghiIds,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // If error 404, one of the ids does not correspond to an existing logo.
      message = "Salvataggio non riuscito: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
