import React, { Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import {
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {
  isLoggedUserAConsulente,
  getConsulenteId,
  isSuperconsulente,
  getGruppoId,
} from "../../utils/storage.js";

import AbbonamentiTable from "./tabelle/AbbonamentiTable";
import ConsulentiTable from "./tabelle/ConsulentiTable";
import EserciziCommercialiTable from "./tabelle/EserciziCommercialiTable";
import ModelliPersonalizzatiTable from "./tabelle/ModelliPersonalizzatiTable.js";
import ManualiPersonalizzatiTable from "./tabelle/ManualiPersonalizzatiTable.js";
import ResponsabiliSicurezzaTable from "./tabelle/ResponsabiliSicurezzaTable";
import GruppiTable from "./tabelle/GruppiTable";
import BrandTable from "./tabelle/BrandTable.js";

import { useTheme } from "@material-ui/core";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
  controlPanelTitle: {
    paddingTop: 30,
    paddingBottom: 20,
    margin: 0,
  },
  controlPanelContainer: {
    padding: "20px",
    overflow: "hidden",
  },
  tab: {
    wordBreak: "break-word",
    boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
    color: "white",
  },
  buttonGroup: {
    margin: 10,
  },
};

export default function ControlPanel() {
  const isConsulente = isLoggedUserAConsulente();
  const match = useRouteMatch();
  const location = useLocation();
  const themeForColors = useTheme();
  return (
    <div id="main_component_container" style={styles.mainContainer}>
      <Typography variant="h4" style={styles.controlPanelTitle}>
        PANNELLO DI CONTROLLO
      </Typography>
      <div style={styles.controlPanelContainer}>
        <Grid container justify="space-evenly">
          <Grid item>
            <AppBar
              position="static"
              style={{ backgroundColor: themeForColors.palette.primary.main }}
            >
              <Tabs
                value={location.pathname}
                indicatorColor="secondary"
                textColor="primary"
                variant="fullWidth"
              >
                {!isConsulente && (
                  <Tab
                    style={{
                      wordBreak: "break-word",
                      boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                      color: themeForColors.palette.text.main,
                    }}
                    label="Brand"
                    component={Link}
                    to={`${match.url}/brand`}
                    value={`${match.url}/brand`}
                  />
                )}
                {isConsulente ? null : (
                  <Tab
                    style={{
                      wordBreak: "break-word",
                      boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                      color: themeForColors.palette.text.main,
                    }}
                    label="Gruppi"
                    component={Link}
                    to={`${match.url}/gruppi`}
                    value={`${match.url}/gruppi`}
                  />
                )}
                <Tab
                  style={{
                    wordBreak: "break-word",
                    boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                    color: themeForColors.palette.text.main,
                  }}
                  label="Responsabili sicurezza"
                  component={Link}
                  to={`${match.url}/responsabili_sicurezza`}
                  value={`${match.url}/responsabili_sicurezza`}
                />
                <Tab
                  style={{
                    wordBreak: "break-word",
                    boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                    color: themeForColors.palette.text.main,
                  }}
                  label={isConsulente ? "Consulente" : "Consulenti"}
                  component={Link}
                  to={`${match.url}/consulenti`}
                  value={`${match.url}/consulenti`}
                />
                <Tab
                  style={{
                    wordBreak: "break-word",
                    boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                    color: themeForColors.palette.text.main,
                  }}
                  label="Esercizi commerciali"
                  component={Link}
                  to={`${match.url}/esercizi_commerciali`}
                  value={`${match.url}/esercizi_commerciali`}
                />
                <Tab
                  style={{
                    wordBreak: "break-word",
                    boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                    color: themeForColors.palette.text.main,
                  }}
                  label="Modelli di manuali"
                  component={Link}
                  to={`${match.url}/modelli_manuali`}
                  value={`${match.url}/modelli_manuali`}
                />
                <Tab
                  style={{
                    wordBreak: "break-word",
                    boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                    color: themeForColors.palette.text.main,
                  }}
                  label="Manuali sicurezza"
                  component={Link}
                  to={`${match.url}/manuali`}
                  value={`${match.url}/manuali`}
                />
                {isConsulente ? null : (
                  <Tab
                    style={{
                      wordBreak: "break-word",
                      boxShadow: "1px 0px 2px 0px rgba(0,0,0,1)",
                      color: themeForColors.palette.text.main,
                    }}
                    label="Abbonamenti"
                    component={Link}
                    to={`${match.url}/abbonamenti`}
                    value={`${match.url}/abbonamenti`}
                  />
                )}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        <Switch>
          <Route path={`${match.path}/:tablename`}>
            <TableComponent />
          </Route>
          <Route path={`${match.path}`}>
            <TextComponent />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

function TableComponent() {
  const theme = useTheme();
  const consulenteId = isLoggedUserAConsulente()
    ? Number.parseInt(getConsulenteId(), 10)
    : null;
  const superconsulenteView = isSuperconsulente() === "true";
  const gruppoId =
    getGruppoId() === null || getGruppoId() === "null"
      ? null
      : Number.parseInt(getGruppoId(), 10);
  let { tablename } = useParams();
  let table = null;
  if (tablename === "responsabili_sicurezza") {
    table = (
      <ResponsabiliSicurezzaTable
        consulenteId={consulenteId}
        superconsulenteView={superconsulenteView}
        gruppoId={gruppoId}
        theme={theme}
      />
    );
  } else if (tablename === "consulenti") {
    table = (
      <ConsulentiTable
        consulenteId={consulenteId}
        superconsulenteView={superconsulenteView}
        gruppoId={gruppoId}
        theme={theme}
      />
    );
  } else if (tablename === "esercizi_commerciali") {
    table = (
      <EserciziCommercialiTable
        consulenteId={consulenteId}
        superconsulenteView={superconsulenteView}
        gruppoId={gruppoId}
        theme={theme}
      />
    );
  } else if (tablename === "modelli_manuali") {
    table = (
      <ModelliPersonalizzatiTable
        consulenteId={consulenteId}
        superconsulenteView={superconsulenteView}
        gruppoId={gruppoId}
        theme={theme}
      />
    );
  } else if (tablename === "manuali") {
    table = (
      <ManualiPersonalizzatiTable
        consulenteId={consulenteId}
        superconsulenteView={superconsulenteView}
        gruppoId={gruppoId}
        theme={theme}
      />
    );
  } else if (tablename === "abbonamenti") {
    table = <AbbonamentiTable theme={theme} />;
  } else if (tablename === "gruppi") {
    table = <GruppiTable theme={theme} />;
  } else if (tablename === "brand") {
    table = <BrandTable />;
  }
  return <Fragment>{table}</Fragment>;
}

function TextComponent() {
  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h5">
        Selezionare la tabella da visualizzare cliccando sul tab corrispondente
      </Typography>
    </div>
  );
}
