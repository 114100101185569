import React from "react";
import { Modal } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ControlliNonConformita from "../manualecomponents/dettaglio_procedura/ControlliNonConformita";
import ActionButton from "../../../components/ActionButton";
import { getLocaliManuale } from "../../../utils/api/locali_e_attrezzature_api";
import PropTypes from "prop-types";
import ParametriSection from "./ParametriSection";
import TwoActionsAlertDialog from "../../../components/TwoActionsAlertDialog";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px",
    padding: "10px",
    boxShadow: theme.shadows[6],
    width: "90%",
    maxWidth: "900px",
    height: "85%",
    minHeight: "420px",
    maxHeight: "620px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  expandedModalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px",
    padding: "10px",
    boxShadow: theme.shadows[6],
    height: "85%",
    overflowY: "auto",
    minHeight: "420px",
    maxHeight: "900px",
    width: "90%",
    maxWidth: "900px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class ModalSelezioneLimiti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlloconformita: this.props.controlloconformita,
      nuoviControlliNonConformita:this.props.controlliSemplificati ? this.props.controlloconformita.controlliNonConformitaSemplificato : this.props.controlloconformita.nonConformita,
      expandedAccordion: false,
      edit:false,
      arrayTabella: [],
      isTwoActionsDialogOpen:false,
      temperaturaMinima: this.props.controlloconformita.temperaturaMinima,
      temperaturaMassima: this.props.controlloconformita.temperaturaMassima,
      temperaturaConsigliata:
        this.props.controlloconformita.temperaturaConsigliata,
      TPM_Minimo: this.props.controlloconformita.TPM_Minimo,
      TPM_Massimo: this.props.controlloconformita.TPM_Massimo,
      TPM_Standard: this.props.controlloconformita.TPM_Standard,
    };
  }

  componentDidMount = () => {
    let repartiControllo = [];
    let reparti = this.props.reparti;
    for (let i = 0; i < reparti.length; i++) {
      if (reparti[i].repartoDefault) {
        repartiControllo.push(reparti[i].id);
        break;
      }
    }
    let checkboxSelected = false;
    if (reparti.length === 1) {
      checkboxSelected = true;
    }
    let tipologieStrumentazioni = this.props.tipologieStrumentazioni;
    let controlloConformita = this.state.controlloconformita;
    if (this.props.controlloconformita === null) {
      controlloConformita.proceduraHaccpId = this.props.proceduraId;
      controlloConformita.reparti = repartiControllo;

      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: true,
        checkboxSelected,
      });
    } else {
      const arrayTabella = !this.props.controlliSemplificati ? this.createTableRecords(
        this.props.controlloconformita.nonConformita
      ) : this.createTableRecords(
        this.props.controlloconformita.controlliNonConformitaSemplificato
      );
      let repartiIsNullOrEmpty =
        this.props.controlloconformita.reparti === null ||
        this.props.controlloconformita.reparti === undefined ||
        this.props.controlloconformita.reparti.length === 0;
      controlloConformita = {
        indice: this.props.controlloconformita.indice,
        id: this.props.controlloconformita.id,
        proceduraHaccpId: this.props.controlloconformita.proceduraHaccpId,
        descrizioneOsservabile:
          this.props.controlloconformita.descrizioneOsservabile !== null
            ? this.props.controlloconformita.descrizioneOsservabile
            : "",
        categoriaControllo:
          this.props.controlloconformita.categoriaControllo !== null
            ? this.props.controlloconformita.categoriaControllo
            : "CONTROLLO_GENERICO",
        categoriaOsservabile:
          this.props.controlloconformita.categoriaOsservabile,
        tipologiaStrumentazioneId:
          this.props.controlloconformita.tipologiaStrumentazioneId === null
            ? -2
            : this.props.controlloconformita.tipologiaStrumentazioneId,
        cadenza:
          this.props.controlloconformita.cadenza === null
            ? "ALTRO"
            : this.props.controlloconformita.cadenza,
        altraCadenza: this.props.controlloconformita.altraCadenza,
        limiti:
          this.props.controlloconformita.limiti === null
            ? ""
            : this.props.controlloconformita.limiti,
        bloccante:
          this.props.controlloconformita.bloccante === null
            ? false
            : this.props.controlloconformita.bloccante,
        periodico: this.props.controlloconformita.periodico,
        controlliNonConformita: [],
        cadenze:
          this.props.controlloconformita.cadenze === null ||
          this.props.controlloconformita.cadenze === undefined
            ? []
            : this.props.controlloconformita.cadenze,
        attrezzature:
          this.props.controlloconformita.attrezzature === null ||
          this.props.controlloconformita.attrezzature === undefined
            ? []
            : this.props.controlloconformita.attrezzature,
        reparti: repartiIsNullOrEmpty
          ? repartiControllo
          : this.props.controlloconformita.reparti,
        temperaturaMinima:
          this.props.controlloconformita.temperaturaMinima === null
            ? ""
            : this.props.controlloconformita.temperaturaMinima,
        temperaturaMassima:
          this.props.controlloconformita.temperaturaMassima === null
            ? ""
            : this.props.controlloconformita.temperaturaMassima,
        temperaturaConsigliata:
          this.props.controlloconformita.temperaturaConsigliata === null
            ? ""
            : this.props.controlloconformita.temperaturaConsigliata,
      };
      // if (controlloConformita.cadenza === null) {
      //     controlloConformita.cadenza = 'ALTRO';
      // }
      // if (controlloConformita.tipologiaStrumentazioneId === null) {
      //     controlloConformita.tipologiaStrumentazioneId = 1;
      // }
      // if (controlloConformita.orarioNotifica === null) {
      //     controlloConformita.orarioNotifica = '08:00:00';
      // }
      // if (controlloConformita.secondoOrarioNotifica === null) {
      //     controlloConformita.secondoOrarioNotifica = '18:00:00';
      // }
      // if (controlloConformita.giornoNotifica === null) {
      //     controlloConformita.giornoNotifica = 'MONDAY';
      // }
      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: false,
        arrayTabella: arrayTabella,
        checkboxSelected,
      });
    }
    this.fetchLocali(controlloConformita.tipologiaStrumentazioneId);
  };

  handleAccordionChange = (event) => {
    const flag = !this.state.expandedAccordion;
    this.setState({
      expandedAccordion: flag,
    });
  };

  createTableRecords = (controlliNonConformita) => {
    if(this.props.controlliSemplificati){
      let arrayTabella = [];
      if(controlliNonConformita.azioniCorrettive !== undefined && controlliNonConformita.azioniCorrettive.length > 0){
        arrayTabella = controlliNonConformita.azioniCorrettive;
      }
      return arrayTabella;
    }else{
    let arrayTabella = [];
    if (controlliNonConformita.length > 0) {
      let controlloNonConf = {};
      let index = 0;
      for (let i = 0; i < controlliNonConformita.length; i++) {
        if (controlliNonConformita[i].controlliCause.length === 0) {
          controlloNonConf = {
            indice: index,
            id: controlliNonConformita[i].id,
            controlloConformitaId: this.state.controlloconformita.id,
            limite: controlliNonConformita[i].limite,
            controlloCausaId: null,
            limiteCausa: null,
            azioneCorrettivaId: null,
            descrizioneAzione: null,
          };
          arrayTabella.push(controlloNonConf);
          index = index + 1;
        } else {
          let controllicause = controlliNonConformita[i].controlliCause;
          for (let j = 0; j < controllicause.length; j++) {
            if (controllicause[j].azioniCorrettive.length === 0) {
              controlloNonConf = {
                indice: index,
                id: controlliNonConformita[i].id,
                controlloConformitaId: this.state.controlloconformita.id,
                limite: controlliNonConformita[i].limite,
                controlloCausaId: controllicause[j].id,
                limiteCausa: controllicause[j].limite,
                azioneCorrettivaId: null,
                descrizioneAzione: null,
              };
              arrayTabella.push(controlloNonConf);
              index = index + 1;
            } else {
              let azionicorrettive = controllicause[j].azioniCorrettive;
              for (let k = 0; k < azionicorrettive.length; k++) {
                controlloNonConf = {
                  indice: index,
                  id: controlliNonConformita[i].id,
                  controlloConformitaId: this.state.controlloconformita.id,
                  limite: controlliNonConformita[i].limite,
                  controlloCausaId: controllicause[j].id,
                  limiteCausa: controllicause[j].limite,
                  azioneCorrettivaId: azionicorrettive[k].id,
                  descrizioneAzione: azionicorrettive[k].descrizione,
                };
                arrayTabella.push(controlloNonConf);
                index = index + 1;
              }
            }
          }
        } //end else-if
      } //end loop on controlli non conformità
    } //end if

    return arrayTabella;
  }
  };

  onAggiungiButtonClickedSemplificato = (value) => {
    if(value !== ''){
      let newAzioniArray = [...this.state.arrayTabella];
      let nuoviControlliNonConformita = {...this.state.nuoviControlliNonConformita};
      newAzioniArray.push({
        descrizione:value,
        id:null,
      })
      nuoviControlliNonConformita.azioniCorrettive = newAzioniArray;
      this.setState({
        arrayTabella: newAzioniArray,
        nuoviControlliNonConformita: nuoviControlliNonConformita,
        edit:true,
      })
    }
  }

  deleteControlloNonConformitaSemplificato = (index) => {
    let newAzioniArray = [...this.state.arrayTabella];
    let nuoviControlliNonConformita = {...this.state.nuoviControlliNonConformita};
    newAzioniArray.splice(index,1);
    nuoviControlliNonConformita.azioniCorrettive = newAzioniArray;
    this.setState({
      arrayTabella: newAzioniArray,
      nuoviControlliNonConformita: nuoviControlliNonConformita,
      edit:true,
    })
  }

  addControlloNonConformita = (nuovoControlloNonConformita) => {
    let arrayOfControlliNonConformita = [
      ...this.state.nuoviControlliNonConformita,
    ];
    arrayOfControlliNonConformita.push(nuovoControlloNonConformita);
    this.setState({
      nuoviControlliNonConformita: arrayOfControlliNonConformita,
    });
    let arrayTabella = this.state.arrayTabella.map((a) => Object.assign({}, a));
    let index = arrayTabella.length;
    let controlloNonConf = {};
    if (nuovoControlloNonConformita.controlliCause.length === 0) {
      controlloNonConf = {
        indice: index,
        id: nuovoControlloNonConformita.id,
        controlloConformitaId: this.state.controlloconformita.id,
        limite: nuovoControlloNonConformita.limite,
        controlloCausaId: null,
        limiteCausa: null,
        azioneCorrettivaId: null,
        descrizioneAzione: null,
      };
      arrayTabella.push(controlloNonConf);
      index = index + 1;
    } else {
      let controllicause = nuovoControlloNonConformita.controlliCause.map((a) =>
        Object.assign({}, a)
      );
      for (let j = 0; j < controllicause.length; j++) {
        if (controllicause[j].azioniCorrettive.length === 0) {
          controlloNonConf = {
            indice: index,
            id: nuovoControlloNonConformita.id,
            controlloConformitaId: this.state.controlloconformita.id,
            limite: nuovoControlloNonConformita.limite,
            controlloCausaId: null,
            limiteCausa: controllicause[j].limite,
            azioneCorrettivaId: null,
            descrizioneAzione: null,
          };
          arrayTabella.push(controlloNonConf);
          index = index + 1;
        } else {
          let azionicorrettive = controllicause[j].azioniCorrettive.map((a) =>
            Object.assign({}, a)
          );
          for (let k = 0; k < azionicorrettive.length; k++) {
            controlloNonConf = {
              indice: index,
              id: nuovoControlloNonConformita.id,
              controlloConformitaId: this.state.controlloconformita.id,
              limite: nuovoControlloNonConformita.limite,
              controlloCausaId: null,
              limiteCausa: controllicause[j].limite,
              azioneCorrettivaId: null,
              descrizioneAzione: azionicorrettive[k].descrizione,
            };
            arrayTabella.push(controlloNonConf);
            index = index + 1;
          }
        }
      }
    } //end else-if

    this.setState({
      expandedAccordion: false,
      hasChanges: true,
      arrayTabella: arrayTabella,
    });
  };

  deleteControlloNonConformita = (record) => {
    let arrayTabella = [...this.state.arrayTabella];
    for (let i = 0; i < arrayTabella.length; i++) {
      if (arrayTabella[i].indice === record.indice) {
        arrayTabella.splice(i, 1);
      }
    }
    let newArrayOfControlliNonConformita = [
      ...this.state.nuoviControlliNonConformita,
    ];
    let controlloIndex = null;
    let controlloCausaIndex = null;
    let azioneCorrettivaIndex = null;
    newArrayOfControlliNonConformita.map((item, index) => {
      if (record.id === item.id) {
        controlloIndex = index;
        item.controlliCause.map((item2, index2) => {
          if (item2.id === record.controlloCausaId) {
            controlloCausaIndex = index2;
            item2.azioniCorrettive.map((item3, index3) => {
              azioneCorrettivaIndex = index3;
            });
          }
        });
      }
    });
    if (
      controlloIndex !== null &&
      controlloCausaIndex !== null &&
      azioneCorrettivaIndex !== null
    ) {
      newArrayOfControlliNonConformita[controlloIndex].controlliCause[
        controlloCausaIndex
      ].azioniCorrettive.splice(azioneCorrettivaIndex, 1);
    }
    if (
      controlloIndex !== null &&
      controlloCausaIndex !== null &&
      azioneCorrettivaIndex === null
    ) {
      newArrayOfControlliNonConformita[controlloIndex].controlliCause.splice(
        controlloCausaIndex,
        1
      );
    }
    if (
      controlloIndex !== null &&
      controlloCausaIndex === null &&
      azioneCorrettivaIndex !== null
    ) {
      newArrayOfControlliNonConformita.splice(controlloIndex, 1);
    }
    if (
      controlloIndex !== null &&
      controlloCausaIndex === null &&
      azioneCorrettivaIndex === null
    ) {
      newArrayOfControlliNonConformita.splice(controlloIndex, 1);
    }
    this.setState({
      nuoviControlliNonConformita: newArrayOfControlliNonConformita,
    });

    if (arrayTabella.length === 0) {
      this.setState({
        nuoviControlliNonConformita: [],
      });
    }
    this.setState({
      hasChanges: true,
      arrayTabella: arrayTabella,
      //nuoviControlliNonConformita:newArrayOfControlliNonConformita
    });
  };

  fetchLocali = (tipologiaStrumentazioneId) => {
    getLocaliManuale(this.props.manualeId, 0, 300, "indiceOrdinamento")
      .then((result) => {
        this.setState({
          locali: result,
        });
        this.fetchAttrezzatureForTipologia(tipologiaStrumentazioneId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const regexTemperatura = /^-?\d*(\.?\d{0,1})$/;
    const regexTPM = /^(100(\.0?)?|(\d{0,2})(\.\d{0,1})?)$/;

    let validationRegex;
    if (name.includes("temperatura")) {
      validationRegex = regexTemperatura;
    } else if (name.includes("TPM")) {
      validationRegex = regexTPM;
    }

    if (validationRegex?.test(value) || value === "") {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSave = () => {
    const {
      mode,
      passLimiti,
      passLimitiTPM,
      passLimitiTemperatura,
      passLimitiOlioFriggitrice,
    } = this.props;

    if (mode === "TPM") {
      passLimitiTPM(
        this.state.nuoviControlliNonConformita,
        this.state.TPM_Minimo,
        this.state.TPM_Massimo,
        this.state.TPM_Standard
      );
    } else if (mode === "CONTROLLO_TEMPERATURE") {
      passLimitiTemperatura(
        this.state.nuoviControlliNonConformita,
        this.state.temperaturaMinima,
        this.state.temperaturaMassima,
        this.state.temperaturaConsigliata
      );
    } else if (mode === "OLIO_FRIGGITRICE") {
      passLimitiOlioFriggitrice(
        this.state.nuoviControlliNonConformita,
        this.state.TPM_Minimo,
        this.state.TPM_Massimo,
        this.state.temperaturaMinima,
        this.state.temperaturaMassima,
        this.state.temperaturaConsigliata
      );
    } else {
      passLimiti(this.state.nuoviControlliNonConformita);
    }
  };

  render() {
    const { classes, open, onClose, mode } = this.props;

    return (
      <Modal open={open} onClose={() => {
                if(this.state.edit){
                /*let confirm = window.confirm("Attenzione, ci sono delle modifiche non salvate. Se si torna indietro, queste verranno perse. Procedere?")
                if(confirm){
                 this.props.onClose();
                }else{
                }*/
                this.setState({
                  isTwoActionsDialogOpen:true,
                })
                }else{
                  this.props.onClose();
                }
        }} className={classes.modal}>
        <div
          className={
            this.state.expandedAccordion
              ? classes.expandedModalContainer
              : classes.modalContainer
          }
        >
          <ParametriSection
            mode={mode}
            state={this.state}
            handleInputChange={this.handleInputChange}
          />
          <div style={{ overflowY: "auto" }}>
            <ControlliNonConformita
              theme={this.props.theme}
              controlliNonConformita={this.state.arrayTabella}
              controlliSemplificati={this.props.controlliSemplificati}
              controlloConformitaId={this.state.controlloconformita.id}
              expandedAccordion={this.state.expandedAccordion}
              onChangeAccordion={this.handleAccordionChange}
              onAggiungiButtonClicked={this.addControlloNonConformita}
              onAggiungiButtonClickedSemplificato={this.onAggiungiButtonClickedSemplificato}
              onDeleteButtonClicked={this.deleteControlloNonConformita}
              onDeleteButtonClickedControlliSemplificato={this.deleteControlloNonConformitaSemplificato}
              mode={mode}
            />
          </div>
          <div className={classes.buttonWrapper}>
            <ActionButton label="Chiudi" secondaryButton onClick={() => {
                if(this.state.edit){
                  this.setState({
                    isTwoActionsDialogOpen:true,
                  })
                }else{
                  this.props.onClose();
                }
        }} />
            <ActionButton label="Salva" onClick={this.handleSave} />
          </div>
          {this.state.isTwoActionsDialogOpen && <TwoActionsAlertDialog
          visible={this.state.isTwoActionsDialogOpen}
          title="Attenzione"
          message="Ci sono delle modifiche sulle azioni correttive non salvate. Se si torna indietro, queste verranno perse. Procedere?"
          onOkClicked={() => {
            this.setState({
              isTwoActionsDialogOpen:false,
            },() => {
              onClose();
            })
          }}
          onIndietroClicked={() => {
            this.setState({
              isTwoActionsDialogOpen:false,
            })
          }}
        />}
        </div>
      </Modal>
    );
  }
}

ModalSelezioneLimiti.propTypes = {
  theme: PropTypes.object.isRequired,
  reparti: PropTypes.array,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  controlloconformita: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  passLimiti: PropTypes.func.isRequired,
  passLimitiTPM: PropTypes.func.isRequired,
  passLimitiTemperatura: PropTypes.func.isRequired,
  passLimitiOlioFriggitrice: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModalSelezioneLimiti);
