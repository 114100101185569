import { Component } from "react";
import React from "react";
import Modal from "@material-ui/core/Modal";
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import { MenuItem, Select } from "@material-ui/core";
import {
  addNewTipologiaStrumentazione,
  deleteTipologiaStrumentazione,
  getAttrezzature,
  getAttrezzatureCount,
  getTipologiaStrumentazione,
  updateAttrezzatureLocale,
} from "../../../utils/api/locali_e_attrezzature_api";

import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "600px",
    display: "flex",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "300px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    justifyContent: "center",
    padding: "10px 0px",
  },
  modalTitle: {
    fontSize: 20,
    alignSelf: "center",
  },
};

export default class ModalSelezioneAttrezzatura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      openAddTipologiaModal: false,
      openRemoveTipologiaModal: false,
      currentTipologiaValue: null,
      selectedTipologiaToRemove: null,
      successfulSave: false,
      records: [],
      editMode: false,
      tipologiaList: [],
      newTipologia: "",
    };
  }

  onAddTipologiaModalClose = () => {
    this.setState({
      openAddTipologiaModal: false,
    });
  };

  onRemoveTipologiaModalClose = () => {
    this.setState({
      openRemoveTipologiaModal: false,
    });
  };

  saveData = () => {
    let array = [...this.state.records];
    let body = {
      manualeId: this.props.manualeId,
      localeId: this.props.selectedLocale.id,
      attrezzature: array,
    };
    updateAttrezzatureLocale(body)
      .then((response) => {
        this.setState({
          successfulSave: true,
        });
        //this.props.onClose();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  findTipologia = (data) => {
    let arrayOfTipologia = [...this.state.tipologiaList];
    let stuff = arrayOfTipologia.find(
      (element) => element.id === data.tipologiaStrumentazioneId
    );
    if (stuff !== undefined) {
      return stuff.nome;
    } else {
      return "";
    }
  };

  handleNewTipologia = () => {
    let newTipologia = this.state.newTipologia;
    let bodyToPass = {
      id: null,
      nome: newTipologia,
      manualeId: this.props.manualeId,
    };
    addNewTipologiaStrumentazione(bodyToPass)
      .then((response) => {
        this.getTipologiaStrumentazioneData();
        this.onAddTipologiaModalClose();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  handleRemoveTipologia = () => {
    let tipologiaToErase = this.state.selectedTipologiaToRemove;
    deleteTipologiaStrumentazione(tipologiaToErase)
      .then((response) => {
        this.getTipologiaStrumentazioneData();
        this.setState(
          {
            selectedTipologiaToRemove: null,
          },
          this.onRemoveTipologiaModalClose()
        );
      })
      .catch((error) => {
        if (error.status === 403 || error.message === 404) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  updateAttrezzaturaLocale = (data, id, index) => {
    let records = [...this.state.records];
    records[index] = data;
    records[index].tipologiaStrumentazioneId = id;
    this.setState({
      records: records,
      successfulSave: false,
    });
    // let body = {
    //   manualeId: this.props.manualeId,
    //   localeId: this.props.selectedLocale.id,
    //   attrezzature: records,
    // };
    /*updateAttrezzatureLocale(body).then((response) => {
        console.log(response);
      }).catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      })*/
  };
  updateAttrezzaturaForNome = () => {
    let records = [...this.state.records];
    let body = {
      manualeId: this.props.manualeId,
      localeId: this.props.selectedLocale.id,
      attrezzature: records,
    };
    updateAttrezzatureLocale(body)
      .then((response) => {})
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  swapElements = (arr, i1, i2) => {
    let temp = arr[i1];

    arr[i1] = arr[i2];

    arr[i2] = temp;
  };
  moveElementUpward = (cellInfo) => {
    let array = [...this.state.records];
    if (cellInfo.index > 0) {
      this.swapElements(array, cellInfo.index, cellInfo.index - 1);
      this.setState({
        records: array,
      });
    }
  };
  moveElementDownWard = (cellInfo) => {
    let array = [...this.state.records];
    if (cellInfo.index < array.length - 1) {
      this.swapElements(array, cellInfo.index, cellInfo.index + 1);
      this.setState({
        records: array,
      });
    }
  };
  deleteElement = (cellInfo) => {
    let array = [...this.state.records];
    array.splice(cellInfo._index, 1);
    this.setState(
      {
        records: array,
        successfulSave: false,
      },
      () => {}
    );

    /*let body = {
        manualeId:this.props.manualeId,
        localeId:this.props.selectedLocale.id,
        attrezzature:array,
      }
      updateAttrezzatureLocale(body).then((response) => {
        console.log(response);
      }).catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      })*/
  };

  handleNewRow = () => {
    let newRowRecords = Array.from(this.state.records);
    let newAttrezzatura = {
      id: null,
      manualeId: this.props.manualeId,
      tipologiaStrumentazioneId: null,
      localeEsercizioId: this.props.selectedLocale.id,
      nome: "",
      quantita: 0,
      indiceOrdinamento: 0,
    };
    newRowRecords.push(newAttrezzatura);
    // let body = {
    //   manualeId: this.props.manualeId,
    //   localeId: this.props.selectedLocale.id,
    //   attrezzature: newRowRecords,
    // };
    this.setState({
      records: newRowRecords,
    });
    /*updateAttrezzatureLocale(body).then(response=>{
        this.getAttrezzaturaData();
      }).catch(error=>{
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      })*/
  };
  getTipologiaStrumentazioneData = () => {
    getTipologiaStrumentazione(this.props.manualeId, 0, 1000, "nome,ASC")
      .then((response) => {
        this.setState({
          tipologiaList: response,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  componentDidMount() {
    this.getAttrezzaturaData();
  }
  getAttrezzaturaData = () => {
    let arrayOfAttrezzature = [];
    getAttrezzatureCount(this.props.manualeId)
      .then((response) => {
        getAttrezzature(
          this.props.manualeId,
          this.props.selectedLocale.id,
          0,
          10000,
          "id"
        ).then((response) => {
          arrayOfAttrezzature = response;
          getTipologiaStrumentazione(
            this.props.manualeId,
            0,
            1000,
            "nome,ASC"
          ).then((response) => {
            let a = this.getAttrezzaturaColumn(arrayOfAttrezzature);
            this.setState({
              columns: a,
              records: arrayOfAttrezzature,
              tipologiaList: response,
            });
          });
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };
  getAttrezzaturaColumn = (data) => {
    return [
      {
        Header: "Tipologia",
        id: "Tipologia",
        //width:175,
        accessor: (data) => data,
        Cell: (props) => (
          <Select
            variant="outlined"
            value={this.findTipologia(props.row.Tipologia)}
            style={{ paddingTop: 0, width: "100%" }}
          >
            <MenuItem
              onClick={() => {
                this.setState({
                  openAddTipologiaModal: true,
                });
              }}
              value={"Aggiungi Tipologia"}
            >
              {"Aggiungi Tipologia"}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({
                  openRemoveTipologiaModal: true,
                });
              }}
              value={"Rimuovi Tipologia"}
            >
              {"Rimuovi Tipologia"}
            </MenuItem>
            {this.state.tipologiaList.map((item, index) => (
              <MenuItem
                value={item.nome}
                key={index}
                onClick={() =>
                  this.updateAttrezzaturaLocale(
                    props.row.Tipologia,
                    item.id,
                    props.row._index
                  )
                }
              >
                {item.nome}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        Header: "Attrezzatura",
        id: "Attrezzatura",
        //width:175,
        accessor: (data) => data.nome,
        Cell: (props) => (
          <TextField
            InputProps={{ disableUnderline: true }}
            disabled={!this.state.editMode}
            onInput={(e) => {
              var AttrezzaturaToChange = [...this.state.records];
              AttrezzaturaToChange[props.row._index].nome = e.target.value;
              props.row.Attrezzatura = e.target.value;
              const caret = e.target.selectionStart;
              const element = e.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records: AttrezzaturaToChange,
                successfulSave: false,
              });
            }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            style={{
              textAlign: "center",
              padding: 10,
              outline: `0 solid transparent`,
            }}
            value={props.row.Attrezzatura}
          ></TextField>
        ),
      },
      {
        Header: "Quantita",
        id: "Quantita",
        //width:100,
        accessor: (data) => data.quantita,
        Cell: (props) => (
          <TextField
            disabled={!this.state.editMode}
            InputProps={{ disableUnderline: true }}
            onInput={(e) => {
              var AttrezzaturaToChange = [...this.state.records];
              AttrezzaturaToChange[props.row._index].quantita = e.target.value;
              props.row.Quantita = e.target.value;
              const caret = e.target.selectionStart;
              const element = e.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records: AttrezzaturaToChange,
                successfulSave: false,
              });
            }}
            inputProps={{
              min: 0,
              style: {
                textAlign: "center",
                inputMode: "numeric",
                pattern: "[0-9]*",
              },
            }}
            style={{
              textAlign: "center",
              padding: 10,
              outline: `0 solid transparent`,
            }}
            value={props.row.Quantita}
          ></TextField>
        ),
      },
      {
        Header: "Posizione",
        id: "Posizione",
        width: 100,
        accessor: (data) => data,
        Cell: (props) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                this.moveElementUpward(props);
              }}
            >
              <KeyboardArrowUp></KeyboardArrowUp>
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                this.moveElementDownWard(props);
              }}
            >
              <KeyboardArrowDown></KeyboardArrowDown>
            </IconButton>
          </div>
        ),
      },
      {
        Header: "Elimina",
        id: "Elimina",
        //width:120,
        accessor: (data) => data,
        Cell: (props) => (
          <Button
            type="button"
            variant="contained"
            size="medium"
            onClick={() => this.deleteElement(props.row)}
            style={{
              margin: 5,
              color: this.props.theme.palette.text.main,
              backgroundColor: this.props.theme.palette.primary.main,
            }}
          >
            ELIMINA
          </Button>
        ),
      },
    ];
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography style={styles.modalTitle}>
              Elenco attrezzature del locale: {this.props.selectedLocale.nome}
            </Typography>
            {this.state.successfulSave && (
              <Typography style={{ color: "green" }}>
                Attrezzature salvate con successo
              </Typography>
            )}
            <div style={{ display: "flex", justifyContent: "right" }}>
              <ActionButton
                label="Aggiungi"
                disabled={false}
                grayVersion={false}
                onClick={this.handleNewRow}
              ></ActionButton>
              <Button
                type="button"
                onClick={() =>
                  this.setState({ editMode: !this.state.editMode })
                }
                variant="contained"
                size="medium"
                style={{
                  color: this.state.editMode
                    ? "white"
                    : this.props.theme.palette.text.main,
                  margin: "10px",
                  backgroundColor: this.state.editMode
                    ? "green"
                    : this.props.theme.palette.primary.main,
                }}
              >
                {this.state.editMode ? "edit on" : "edit off"}
              </Button>
            </div>
            {/*<Typography>La tipologia di strumentazione indica la "famiglia" di strumenti a cui appartiene una certa attrezzatura.
                    Per inserire un'attrezzatura selezionare la tipologia di cui fa parte e scrivere nel campo "nome" un etichetta o una breve descrizione che possa
                    aiutare a distinguere questa specifica attrezzatura dalle altre attrezzature dell'esercizio commerciale.
                    Ad esempio: tipologia FRIGORIFERO nome="frigo a due ante marca xx"</Typography>*/}
            <ReactTable
              filterable={true}
              resizable={true}
              enableEditing={true}
              showPageSizeOptions={false}
              showPageJump={true}
              defaultPageSize={10}
              style={{
                height: 400,
                width: "40vw",
              }}
              //pages={this.state.pages}
              data={this.state.records}
              columns={this.state.columns}
              //manual // informs React Table that you'll be handling sorting and pagination server-side
              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun record"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
            <div style={styles.buttoncontainer}>
              <ActionButton
                disabled={false}
                grayVersion={false}
                label="Salva Modifiche"
                onClick={this.saveData}
              ></ActionButton>
              <ActionButton
                disabled={false}
                grayVersion={false}
                secondaryButton
                label="Indietro"
                onClick={this.props.onClose}
              ></ActionButton>
            </div>
          </div>
          <Modal
            open={this.state.openAddTipologiaModal}
            onClose={this.onAddTipologiaModalClose}
          >
            <div style={styles.miniModalContainer}>
              <div style={styles.modalsectioncontainer}>
                <Typography style={styles.modalTitle}>
                  Aggiungi tipologia di strumentazione
                </Typography>
                <div style={{ display: "flex", marginTop: "10%" }}>
                  <Typography style={{ margin: 10 }}>
                    Nuova tipologia:{" "}
                  </Typography>
                  <TextField
                    value={this.state.newTipologia}
                    onInput={(e) => {
                      let tempNewTipologia = this.state.newTipologia;
                      tempNewTipologia = e.target.value;
                      this.setState({
                        newTipologia: tempNewTipologia,
                      });
                    }}
                    variant="outlined"
                    size="small"
                  ></TextField>
                </div>
                <div style={styles.buttoncontainer}>
                  <ActionButton
                    disabled={false}
                    grayVersion={false}
                    label="Salva Modifiche"
                    onClick={this.handleNewTipologia}
                  ></ActionButton>
                  <ActionButton
                    disabled={false}
                    grayVersion={false}
                    secondaryButton
                    label="Indietro"
                    onClick={this.onAddTipologiaModalClose}
                  ></ActionButton>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={this.state.openRemoveTipologiaModal}
            onClose={this.onRemoveTipologiaModalClose}
          >
            <div style={styles.miniModalContainer}>
              <div style={styles.modalsectioncontainer}>
                <Typography style={styles.modalTitle}>
                  Rimuovi tipologia di strumentazione
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10%",
                  }}
                >
                  <Typography style={{ margin: 20 }}>
                    Selezionare tipologia da rimuovere:{" "}
                  </Typography>
                  <Select
                    variant="outlined"
                    value={this.state.selectedTipologiaToRemove}
                    onChange={(e) => {
                      this.setState({
                        selectedTipologiaToRemove: e.target.value,
                      });
                    }}
                    style={{ paddingTop: 0, width: "100%" }}
                  >
                    {this.state.tipologiaList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.nome}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div style={styles.buttoncontainer}>
                  <ActionButton
                    disabled={false}
                    grayVersion={false}
                    label="Rimuovi"
                    onClick={this.handleRemoveTipologia}
                  ></ActionButton>
                  <ActionButton
                    disabled={false}
                    grayVersion={false}
                    label="Indietro"
                    onClick={this.onRemoveTipologiaModalClose}
                  ></ActionButton>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {/**/}
      </Modal>
    );
  }
}
