import React from "react";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  roundedInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "30px",
      },
      "&:hover fieldset": {
        borderColor: "grey",
      },
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
  },
  urlFieldWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  rotatedIcon: {
    transform: "rotate(-45deg)",
  },
}));

const BrandUrlField = ({
  url,
  onUrlChange,
  isUrlEditable,
  handleEditUrlClick,
  isEditMode,
  setIsUrlEditable,
}) => {
  const classes = useStyles();

  return (
    <div>
      {isEditMode ? (
        <Typography>URL del brand</Typography>
      ) : (
        <Typography>La tua URL sarà:</Typography>
      )}
      <div className={classes.urlFieldWrapper}>
        <TextField
          className={classes.roundedInput}
          variant="outlined"
          value={url}
          onChange={onUrlChange}
          onBlur={setIsUrlEditable}
          disabled={!isUrlEditable}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Link className={classes.rotatedIcon} />
              </InputAdornment>
            ),
          }}
        />
        {!isEditMode && (
          <Button disabled={isUrlEditable} variant="contained" onClick={handleEditUrlClick}>
            Modifica URL
          </Button>
        )}
      </div>
    </div>
  );
};

BrandUrlField.propTypes = {
  url: PropTypes.string.isRequired,
  onUrlChange: PropTypes.func.isRequired,
  isUrlEditable: PropTypes.bool.isRequired,
  handleEditUrlClick: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

export default BrandUrlField;
