import React from "react";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Settings from "@material-ui/icons/Settings";
import Save from "@material-ui/icons/Save";
import HighlightOff from "@material-ui/icons/HighlightOff";
import RestoreIcon from "@material-ui/icons/Restore";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import TwoActionsAlertDialog from "../../../components/TwoActionsAlertDialog";
import SelezioneGruppo from "../components/SelezioneGruppo";
import ActionButton from "../../../components/ActionButton";
import ModalSelezioneGruppo from "../components/ModalSelezioneGruppo";
import ModalSelezioneConsulente from "../components/ModalSelezioneConsulente";
import ModalSelezioneEsercizioCommerciale from "../components/ModalSelezioneEsercizioCommerciale";
import GoToPageModal from "../components/GoToPageModal";
import ModificaModelloManualeButton from "../components/ModificaModelloManualeButton";
import ModalSelezioneModalitaModello from "../components/ModalSelezioneModalitaModello";

import { clearToken } from "../../../utils/storage";
import { getBrands } from "../../../utils/api/brand_api";
import { getGruppi } from "../../../utils/api/gruppi_api";

import {
  cancellaModello,
  creaManualePersonalizzato,
  creaModelloPersonalizzato,
  getFlagControlliSemplificatiManualeFromId,
  getModelli,
  getModelliCount,
  getModelliCountFiltered,
  getModelliFiltered,
  updateModello,
} from "../../../utils/api/modelli_and_manuali_api";

import { getCapitoliCount } from "../../../utils/api/capitoli_api";

import theme from "../../../theme.js";

import moment from "moment";
import "moment/locale/it";

import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import LoadingModal from "../components/LoadingModal";
import BasicTable from "../../../components/BasicTable.js";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
  vediButton: {
    color: theme.palette.secondary.main,
    padding: "0px",
    margin: "0px",
    backgroundColor: theme.palette.primary.main,
  },
};

const dateFormat = "DD-MM-YYYY HH:mm";

export const Component = withRouter(({ history, location }) => {});

class ModelliPersonalizzatiTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelli: [],
      brands: [],
      gruppoId: null,
      gruppi: [],
      esercizioId: null,
      selectedGruppoId: null,
      consulenteId: null,
      loading: true,
      modelloBaseId: null,
      modelloCreatoId: null,
      errorDialogVisible: false,
      errorDialogMessage: "",
      edit: false,
      showHiddenModels: false,
      twoActionsAlertVisible: false,
      modalTitle: "",
      modalMessage: "",
      modelloSelezionato: null,
      newModelloId: null,
      showModalSelezioneGruppo: false,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: false,
      showModalSelezioneModalita: false,
      isChapterSelectionBlocked: false,
      isControlliSelectionBlocked: false,
      loadingModal: false,
      showGoToPageModal: false,
      goToPageMessage: "",
      goToPagePath: "",
      isModello: false,
      origin: "Manuale",
      totalPages: 10,
      loadedPage: 0,
      pageSize: 10,
      filters: {
        idModello: "",
        idGruppo: "",
        titolo: "",
        regolamenti: "",
      },
    };
  }

  componentDidMount() {
    let gruppoId = this.props.gruppoId; // Can be null if user has role "ADMIN"
    this.setState({
      gruppoId,
      selectedGruppoId: gruppoId,
    });
    if (this.props.consulenteId === null) {
      // User is admin
      this.fetchGruppi();
    } else {
      this.fetchBrands(gruppoId);
    }
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  onPageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
        edit: false,
      },
      () =>
        this.fetchModelliOnPageChange(
          this.state.filters.idGruppo,
          this.state.showHiddenModels,
          pageIndex
        )
    );
  };

  generateFilterColumn = ({ id, placeholder, filterValue, onChange }) => {
    return {
      Header: placeholder,
      id: id,
      accessor: (d) => d[id],
      filterable: true,
      minWidth: 120,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={this.state.edit}
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        loadedPage: 0,
                      },
                      () => {
                        this.fetchModelliForResearch(
                          this.state.filters.idGruppo,
                          this.state.showHiddenModels
                        );
                      }
                    );
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  generateFilterColumnWithEditableFields = ({
    id,
    placeholder,
    filterValue,
    onChange,
  }) => {
    return {
      Header: placeholder,
      id: id,
      accessor: (d) => d[id],
      filterable: true,
      minWidth: 120,
      Cell: this.renderEditable,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={this.state.edit}
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        loadedPage: 0,
                      },
                      () => {
                        this.fetchModelliForResearch(
                          this.state.filters.idGruppo,
                          this.state.showHiddenModels
                        );
                      }
                    );
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  generateFilterColumnWithEditableFields = ({
    id,
    placeholder,
    filterValue,
    onChange,
  }) => {
    return {
      Header: placeholder,
      id: id,
      accessor: (d) => d[id],
      filterable: true,
      minWidth: 120,
      Cell: this.renderEditable,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={this.state.edit}
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        loadedPage: 0,
                      },
                      () => {
                        this.fetchModelliForResearch(
                          this.state.filters.idGruppo,
                          this.state.showHiddenModels
                        );
                      }
                    );
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  fetchGruppi = () => {
    getGruppi(null, null, 0, 3000, "id")
      .then((result) => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId,
        });
        this.fetchBrands();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  fetchBrands = () => {
    getBrands(0, 1000, "id")
      .then((result) => {
        this.setState({
          brands: result,
        });
        this.fetchModelli(this.props.gruppoId, this.state.showHiddenModels);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  fetchModelli = (gruppoId, hidden) => {
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;
    if (this.props.consulenteId !== null) {
      gruppoId = this.props.gruppoId;
    }
    getModelliCount(hidden, gruppoId, isConsulente, null)
      .then((countResult) => {
        getModelli(hidden, gruppoId, isConsulente, null, 0, 10, "id").then(
          (result) => {
            this.setState({
              loading: false,
              modelli: result,
              totalPages: Math.ceil(countResult / 10),
              loadedPage: 0,
              filters: {
                idModello: "",
                idGruppo: gruppoId === null ? -1 : gruppoId,
                titolo: "",
                regolamenti: "",
              },
            });
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };
  fetchModelliOnPageChange = (gruppoId, hidden, currentPage) => {
    const { filters } = this.state;
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;
    if (this.props.consulenteId !== null) {
      gruppoId = this.props.gruppoId;
    } else {
      gruppoId = this.state.filters.idGruppo;
    }
    getModelliFiltered(
      hidden,
      gruppoId,
      isConsulente,
      null,
      currentPage,
      10,
      "id",
      filters.idModello,
      filters.titolo,
      filters.regolamenti
    )
      .then((result) => {
        this.setState({
          loading: false,
          modelli: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };
  fetchModelliForResearch = (gruppoId, hidden) => {
    const { filters } = this.state;
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;
    if (this.props.consulenteId !== null) {
      gruppoId = this.props.gruppoId;
    } else {
      gruppoId = this.state.filters.idGruppo;
    }
    getModelliCountFiltered(
      hidden,
      gruppoId,
      isConsulente,
      null,
      filters.idModello,
      filters.titolo,
      filters.regolamenti
    )
      .then((countResult) => {
        getModelliFiltered(
          hidden,
          gruppoId,
          isConsulente,
          null,
          this.state.loadedPage,
          10,
          "id",
          filters.idModello,
          filters.titolo,
          filters.regolamenti
        )
          .then((result) => {
            this.setState({
              loading: false,
              modelli: result,
              totalPages:
                Math.ceil(countResult / 10) > 0
                  ? Math.ceil(countResult / 10)
                  : 1,
              loadedPage: 0,
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.setState({
                loading: false,
              });
              this.handleError(true, error.message);
            }
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  updateModello = () => {
    updateModello(this.state.modelloSelezionato)
      .then((result) => {
        // TEMPORARY DISABLED FETCH REQUEST AFTER UPDATE:
        //this.fetchModelli(this.state.gruppoId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  executeCreationModelloPersonalizzato = (
    manualeBaseId,
    gruppoId,
    hasChapters,
    isModello,
    isControlloSemplificato
  ) => {
    if (!isModello) {
      this.setState(
        {
          showModalSelezioneModalita: false,
          loadingModal: true,
        },
        () => {
          creaManualePersonalizzato(
            this.state.esercizioId,
            manualeBaseId,
            this.state.consulenteId,
            hasChapters,
            isControlloSemplificato
          )
            .then((result) => {
              this.setState(
                {
                  loadingModal: false,
                },
                () => {
                  this.openGoToModal(false, result.manuale.id, hasChapters);
                }
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                  loadingModal: false,
                });
                this.handleError(true, error.message);
              }
            });
        }
      );
    } else {
      this.setState(
        {
          showModalSelezioneModalita: false,
          loadingModal: true,
        },
        () => {
          creaModelloPersonalizzato(
            manualeBaseId,
            gruppoId,
            hasChapters,
            isControlloSemplificato
          )
            .then((result) => {
              this.setState(
                {
                  loadingModal: false,
                },
                () => {
                  this.openGoToModal(true, result.id, hasChapters);
                }
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                  loadingModal: false,
                });
                this.handleError(true, error.message);
              }
            });
        }
      );
    }
  };

  openGoToModal = (isModello, modelloId, hasChapters) => {
    let message =
      "Clicca su Avanti per accedere alla pagina di costruzione del contenuto del modello.";
    let path =
      "/amministrazione/manuali/nuovomanuale/" +
      modelloId +
      "/modello/" +
      hasChapters;
    if (!isModello) {
      message =
        "Clicca su Avanti per accedere alla pagina di costruzione del contenuto del manuale.";
      path =
        "/amministrazione/manuali/nuovomanuale/" +
        modelloId +
        "/manuale/" +
        hasChapters;
    }
    this.setState({
      showGoToPageModal: true,
      showModalSelezioneModalita: false,
      goToPageMessage: message,
      goToPagePath: path,
    });
  };

  onGoToPageCanceled = () => {
    let isAdmin =
      !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      this.setState(
        {
          showGoToPageModal: false,
          loading: true,
          gruppoId: -1,
        },
        () => {
          this.fetchModelli(this.state.gruppoId, this.state.showHiddenModels);
        }
      );
    } else {
      this.setState(
        {
          showGoToPageModal: false,
          loading: true,
        },
        () => {
          this.fetchModelli(this.state.gruppoId, this.state.showHiddenModels);
        }
      );
    }
  };

  handleSelectedGruppo = (gruppoId) => {
    let prevFilters = this.state.filters;
    prevFilters.idGruppo = gruppoId;
    this.setState({
      //gruppoId,
      loadedPage: 0,
      filters: prevFilters,
      loading: true,
    });
    this.fetchModelliForResearch(gruppoId, this.state.showHiddenModels);
  };

  onHiddenManualiSelection = (e) => {
    let hidden = e.target.value;
    this.setState({
      showHiddenModels: hidden,
      loading: true,
      loadedPage: 0,
    });
    this.fetchModelliForResearch(this.state.filters.idGruppo, hidden);
  };

  onCreaModelloButtonClicked = () => {
    const isAdmin = this.props.consulenteId === null;
    if (isAdmin) {
      this.setState({
        showModalSelezioneGruppo: true,
        modelloBaseId: null,
        origin: "Modello",
        isModello: true,
      });
    } else {
      this.setState(
        {
          isModello: true,
          origin: "Modello",
          modelloBaseId: null,
        },
        () => {
          this.onOpenModalModalita();
        }
      );
      //this.executeCreationModelloPersonalizzato(null, this.props.gruppoId);
    }
  };

  onCancelGruppoSelection = () => {
    let currentGruppoId = this.state.gruppoId;
    this.setState({
      showModalSelezioneGruppo: false,
      loading: false,
      selectedGruppoId: currentGruppoId,
    });
  };

  saveSelectedGruppoForModello = (gruppoId) => {
    this.setState({
      selectedGruppoId: gruppoId,
      gruppoId: gruppoId,
    });
  };

  onCreaButtonInModalClicked = (origin) => {
    if (origin === "Modello") {
      let gruppoId = this.state.selectedGruppoId;
      this.setState(
        {
          showModalSelezioneGruppo: false,
          selectedGruppoId: gruppoId,
          loading: true,
        },
        this.onOpenModalModalita()
      );
    } else {
      let gruppoId = this.state.selectedGruppoId;
      this.setState({
        showModalSelezioneGruppo: false,
        selectedGruppoId: gruppoId,
        loading: true,
        showModalSelezioneConsulente: true,
      });
    }
    //this.executeCreationModelloPersonalizzato(this.state.modelloBaseId, gruppoId);
  };

  onPublicationButtonClicked = () => {
    let modelloSelezionato = this.state.modelloSelezionato;
    modelloSelezionato.stato = "PUBBLICATO";
    this.setState({
      modelloSelezionato,
      modalTitle: "Pubblicazione bozza",
      modalMessage:
        "Cliccare su OK per confermare la richiesta di pubblicazione oppure Indietro per annullare la richiesta.",
      twoActionsAlertVisible: true,
      edit: false,
    });
  };

  onPubblicationCancelled = () => {
    let modelloSelezionato = this.state.modelloSelezionato;
    if (modelloSelezionato.stato === "PUBBLICATO") {
      modelloSelezionato.stato = "BOZZA";
      this.setState({
        modelloSelezionato,
        twoActionsAlertVisible: false,
      });
    }
  };

  onAlertOkButtonPressed = () => {
    this.setState({
      twoActionsAlertVisible: false,
    });
    this.updateModello();
  };

  onModificaClicked = (record, event) => {
    if (!this.state.edit) {
      this.setState({
        edit: true,
        modelloSelezionato: record,
      });
    } else {
      this.setState({
        edit: false,
      });
      this.updateModello();
    }
  };

  onClonaClicked = (record, event) => {
    let selectgruppo = this.props.consulenteId === null ? true : false; // If admin, open modal to select gruppo
    if (selectgruppo) {
      this.setState({
        modelloBaseId: record.id,
        showModalSelezioneGruppo: true,
        origin: "Modello",
        isModello: true,
      });
    } else {
      this.setState(
        {
          loading: true,
          modelloBaseId: record.id,
          selectedGruppoId: record.gruppoId,
        },
        () => {
          getCapitoliCount(this.state.modelloBaseId)
            .then((result) => {
              getFlagControlliSemplificatiManualeFromId(this.state.modelloBaseId)
              .then((flagControlli) => {
              if (result === 0) {
                this.setState({
                  showModalSelezioneModalita: true,
                  isModello: true,
                  isControlliSelectionBlocked: flagControlli,
                  isChapterSelectionBlocked: false,
                });
              } else {
                this.setState(
                  {
                    //loadingModal: true,
                    isModello: true,
                    isChapterSelectionBlocked: true,
                    isControlliSelectionBlocked: flagControlli,
                    showModalSelezioneModalita: true,
                  }
                  /*() => {
                    creaModelloPersonalizzato(
                      this.state.modelloBaseId,
                      record.gruppoId,
                      false
                    )
                      .then((result) => {
                        this.setState(
                          {
                            loadingModal: false,
                          },
                          () => {
                            this.openGoToModal(false, result.id, true);
                          }
                        );
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.handleInvalidToken();
                        } else {
                          this.setState({
                            loading: false,
                            loadingModal: false,
                          });
                          this.handleError(true, error.message);
                        }
                      });
                  }*/
                );
              }})
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            });
        }
      );
    }
  };

  /*
  onOpenModalModalita = (consulenteId, esercizioId) => {
    const { history } = this.props;
    if (this.state.modelloBaseId === null) {
      this.setState({
        consulenteId,
        esercizioId,
        showModalSelezioneEsercizio: false,
        showModalSelezioneModalita: true,
      });
    } else {
      getCapitoliCount(this.state.modelloBaseId)
      .then(result => {
        if (result === 0) {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio: false,
            showModalSelezioneModalita: true,
          });
        } else {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio:false,
            showModalSelezioneModalita: false,
          }, () => creaManualePersonalizzato(esercizioId, this.state.modelloBaseId, consulenteId, false)
            .then(result => {
              this.openGoToModal(false, this.state.modelloBaseId);
              // history.push('/amministrazione/manuali/nuovomanuale/' + this.state.modelloBaseId + '/manuale');
            })
            .catch(error => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            }));
        }
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
    }
  }*/

  onCreaManualeDaClicked = (record, event) => {
    if (this.props.gruppoId === null) {
      this.setState({
        loading: true,
        modelloBaseId: record.id,
        gruppoId: record.gruppoId,
        isModello: false,
        origin: "Manuale",
        showModalSelezioneConsulente: true,
      });
    } else if (this.props.superconsulenteView) {
      this.setState({
        loading: true,
        modelloBaseId: record.id,
        isModello: false,
        //showModalSelezioneGruppo:true,
        origin: "Manuale",
        showModalSelezioneConsulente: true,
      });
    } else {
      // If user is not admin nor superconsulente:
      let consulenteId = this.props.consulenteId;
      this.setState({
        loading: true,
        consulenteId,
        modelloBaseId: record.id,
        showModalSelezioneEsercizio: true,
      });
    }
  };

  handleCloseConsulentiModal = () => {
    this.setState({
      loading: false,
      showModalSelezioneConsulente: false,
    });
  };

  handleOpenEserciziModal = (consulenteId) => {
    this.setState({
      consulenteId,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: true,
    });
  };

  handleCloseEserciziModal = () => {
    this.setState({
      loading: false,
      showModalSelezioneEsercizio: false,
    });
  };
  onOpenModalModalita = (consulenteId, esercizioId) => {
    if (this.state.modelloBaseId === null) {
      this.setState({
        consulenteId,
        esercizioId,
        showModalSelezioneEsercizio: false,
        showModalSelezioneModalita: true,
        isChapterSelectionBlocked: false,
        isControlliSelectionBlocked: false,
      });
    } else {
      getCapitoliCount(this.state.modelloBaseId)
        .then((result) => {
          getFlagControlliSemplificatiManualeFromId(this.state.modelloBaseId)
            .then((flagControlli) => {
              if (result === 0) {
                this.setState({
                  consulenteId,
                  esercizioId,
                  showModalSelezioneEsercizio: false,
                  showModalSelezioneModalita: true,
                  isChapterSelectionBlocked: false,
                  isControlliSelectionBlocked: flagControlli,
                });
              } else {
                this.setState(
                  {
                    consulenteId,
                    esercizioId,
                    showModalSelezioneEsercizio: false,
                    showModalSelezioneModalita: true,
                    isChapterSelectionBlocked: true,
                    isControlliSelectionBlocked: flagControlli,
                    //loadingModal: true,
                  }
                  /*() => {
                  if (this.state.origin === "Modello") {
                    creaModelloPersonalizzato(
                      this.state.modelloBaseId,
                      this.state.selectedGruppoId,
                      false
                    )
                      .then((result) => {
                        this.setState(
                          {
                            loadingModal: false,
                          },
                          () => {
                            this.openGoToModal(true, result.id, true);
                          }
                        );
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.handleInvalidToken();
                        } else {
                          this.setState({
                            loading: false,
                            loadingModal: false,
                          });
                          this.handleError(true, error.message);
                        }
                      });
                  } else {
                    creaManualePersonalizzato(
                      esercizioId,
                      this.state.modelloBaseId,
                      consulenteId,
                      false
                    )
                      .then((result) => {
                        this.setState(
                          {
                            loadingModal: false,
                          },
                          () => {
                            this.openGoToModal(false, result.manuale.id, true);
                          }
                        );
                        // history.push('/amministrazione/manuali/nuovomanuale/' + this.state.modelloBaseId + '/manuale');
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.handleInvalidToken();
                        } else {
                          this.setState({
                            loading: false,
                            loadingModal: false,
                          });
                          this.handleError(true, error.message);
                        }
                      });
                  }
                }*/
                );
              }
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    }
  };

  onCloseModalModalita = () => {
    //Insert here the code to close the modal for admin
    let isAdmin =
      !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      this.setState({
        showModalSelezioneModalita: false,
        loading: false,
        gruppoId: -1,
      });
    } else {
      this.setState({
        showModalSelezioneModalita: false,
        loading: false,
      });
    }
  };

  onCancellaOrRipristinaButtonClicked = (modello, e) => {
    this.setState({
      loading: false,
    });
    let hidden = this.state.showHiddenModels ? false : true;
    cancellaModello(modello.id, hidden)
      .then((result) => {
        this.fetchModelli(this.state.gruppoId, this.state.showHiddenModels);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  getColumns = () => {
    let isSuperConsulente = this.props.superconsulenteView;
    let isAdmin =
      !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      return [
        {
          Header: "Numero",
          id: "numero",
          accessor: (row) => row,
          width: 120,
          filterable: false,
          Cell: (props) =>
            this.state.loadedPage === 0
              ? "" + props.index
              : this.state.loadedPage + "" + props.index,
        },
        this.generateFilterColumn({
          id: "id",
          placeholder: "ID modello",
          filterValue: this.state.filters.idModello,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filters: {
                  ...prevState.filters,
                  idModello: value,
                },
              }));
            }
          },
        }),
        {
          Header: "ID modello base",
          id: "manualeBaseId",
          accessor: (d) => d.manualeBaseId,
          width: 154,
          filterable: false,
          Cell: (props) => (props.value === null ? " - " : props.value),
        },
        {
          id: "gruppoId",
          Header: "ID gruppo",
          accessor: (row) => row.gruppoId,
          filterable: false,
        },
        {
          Header: "Brand",
          id: "brand",
          accessor: (d) => d.brandId,
          width: 120,
          filterable: false,
          Cell: (props) => {
            let brand = "";
            for (let i = 0; i < this.state.brands.length; i++) {
              if (this.state.brands[i].id === props.value) {
                brand = this.state.brands[i].nome;
                break;
              }
            }
            return brand;
          },
        },
        this.generateFilterColumnWithEditableFields({
          id: "titolo",
          placeholder: "Titolo",
          filterValue: this.state.filters.titolo,
          onChange: (value) => {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                titolo: value,
              },
            }));
          },
        }),
        {
          Header: "Data aggiornamento",
          id: "dataUltimoAggiornamento",
          filterable: false,
          accessor: (d) => moment(d.dataUltimoAggiornamento).format(dateFormat),
          width: 160,
        },
        this.generateFilterColumnWithEditableFields({
          id: "regolamenti",
          placeholder: "Regolamenti",
          filterValue: this.state.filters.regolamenti,
          onChange: (value) => {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                regolamenti: value,
              },
            }));
          },
        }),
        {
          Header: "Stato pubblicazione",
          id: "stato",
          accessor: (d) => d.stato,
          minWidth: 180,
          filterable: false,
          Cell: this.renderEditableStato,
        },
        {
          id: "modifica",
          Header: "Modifica",
          sortable: false,
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          filterable: false,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              style={{
                color:
                  props.value.stato === "PUBBLICATO"
                    ? theme.palette.disabled.main
                    : this.props.theme.palette.primary.main,
              }}
              size="small"
              onClick={(e) => {
                this.onModificaClicked(props.value, e);
              }}
              disabled={props.value.stato === "PUBBLICATO" ? true : false}
            >
              {this.state.edit ? <Save /> : <Settings />}
            </IconButton>
          ),
        },
        {
          id: "modificaContenuto",
          Header: "Modifica Contenuto",
          show: this.state.showHiddenModels ? false : true,
          width: 180,
          filterable: false,
          accessor: (row) => row,
          Cell: (props) => (
            <ModificaModelloManualeButton
              disabled={props.value.stato === "PUBBLICATO" ? true : false}
              modelloId={props.value.id}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
            />
          ),
        },
        {
          id: "clona",
          Header: "Clona",
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          filterable: false,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              size="small"
              disabled={props.value.stato === "BOZZA" ? true : false}
              style={{
                color:
                  props.value.stato === "BOZZA"
                    ? theme.palette.disabled.main
                    : this.props.theme.palette.primary.main,
              }}
              onClick={(e) => {
                this.onClonaClicked(props.value, e);
              }}
            >
              <FileCopyIcon />
            </IconButton>
          ),
        },
        {
          id: "creamanuale",
          Header: "Crea manuale da",
          show: this.state.showHiddenModels ? false : true,
          width: 152,
          filterable: false,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              size="small"
              disabled={props.value.stato === "BOZZA" ? true : false}
              style={{
                color:
                  props.value.stato === "BOZZA"
                    ? theme.palette.disabled.main
                    : this.props.theme.palette.primary.main,
              }}
              onClick={(e) => {
                this.onCreaManualeDaClicked(props.value, e);
              }}
            >
              <PostAddIcon />
            </IconButton>
          ),
        },
        {
          id: "cancella",
          Header: this.state.showHiddenModels ? "Ripristina" : "Elimina",
          width: 80,
          filterable: false,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              style={{ color: this.props.theme.palette.primary.main }}
              size="small"
              onClick={(e) => {
                this.onCancellaOrRipristinaButtonClicked(props.value, e);
              }}
            >
              {this.state.showHiddenModels ? <RestoreIcon /> : <HighlightOff />}
            </IconButton>
          ),
        },
      ];
    } else if (isSuperConsulente) {
      return [
        {
          Header: "Numero",
          id: "numero",
          accessor: (row) => row,
          width: 120,
          filterable: false,
          Cell: (props) =>
            this.state.loadedPage === 0
              ? "" + props.index
              : this.state.loadedPage + "" + props.index,
        },
        this.generateFilterColumn({
          id: "id",
          placeholder: "ID modello",
          filterValue: this.state.filters.idModello,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filters: {
                  ...prevState.filters,
                  idModello: value,
                },
              }));
            }
          },
        }),
        {
          Header: "ID modello base",
          id: "manualeBaseId",
          filterable: false,
          accessor: (d) => d.manualeBaseId,
          width: 154,
          Cell: (props) => (props.value === null ? " - " : props.value),
        },
        {
          Header: "Brand",
          id: "brand",
          accessor: (d) => d.brandId,
          width: 120,
          filterable: false,
          Cell: (props) => {
            let brand = "";
            for (let i = 0; i < this.state.brands.length; i++) {
              if (this.state.brands[i].id === props.value) {
                brand = this.state.brands[i].nome;
                break;
              }
            }
            return brand;
          },
        },
        this.generateFilterColumnWithEditableFields({
          id: "titolo",
          placeholder: "Titolo",
          filterValue: this.state.filters.titolo,
          onChange: (value) => {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                titolo: value,
              },
            }));
          },
        }),
        {
          Header: "Data aggiornamento",
          id: "dataUltimoAggiornamento",
          filterable: false,
          accessor: (d) => moment(d.dataUltimoAggiornamento).format(dateFormat),
          width: 160,
        },
        this.generateFilterColumnWithEditableFields({
          id: "regolamenti",
          placeholder: "Regolamenti",
          filterValue: this.state.filters.regolamenti,
          onChange: (value) => {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                regolamenti: value,
              },
            }));
          },
        }),
        {
          Header: "Stato pubblicazione",
          id: "stato",
          filterable: false,
          accessor: (d) => d.stato,
          minWidth: 180,
          Cell: this.renderEditableStato,
        },
        {
          id: "modifica",
          Header: "Modifica",
          sortable: false,
          filterable: false,
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              style={{
                color:
                  props.value.stato === "PUBBLICATO"
                    ? theme.palette.disabled.main
                    : this.props.theme.palette.primary.main,
              }}
              size="small"
              onClick={(e) => {
                this.onModificaClicked(props.value, e);
              }}
              disabled={props.value.stato === "PUBBLICATO" ? true : false}
            >
              {this.state.edit ? <Save /> : <Settings />}
            </IconButton>
          ),
        },
        {
          id: "modificaContenuto",
          Header: "Modifica Contenuto",
          show: this.state.showHiddenModels ? false : true,
          filterable: false,
          width: 180,
          accessor: (row) => row,
          Cell: (props) => (
            <ModificaModelloManualeButton
              disabled={props.value.stato === "PUBBLICATO" ? true : false}
              modelloId={props.value.id}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
            />
          ),
        },
        {
          id: "clona",
          Header: "Clona",
          filterable: false,
          show: this.state.showHiddenModels ? false : true,
          width: 80,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              size="small"
              disabled={props.value.stato === "BOZZA" ? true : false}
              style={{
                color:
                  props.value.stato === "BOZZA"
                    ? theme.palette.disabled.main
                    : this.props.theme.palette.primary.main,
              }}
              onClick={(e) => {
                this.onClonaClicked(props.value, e);
              }}
            >
              <FileCopyIcon />
            </IconButton>
          ),
        },
        {
          id: "creamanuale",
          Header: "Crea manuale da",
          filterable: false,
          show: this.state.showHiddenModels ? false : true,
          width: 152,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              size="small"
              disabled={props.value.stato === "BOZZA" ? true : false}
              style={{
                color:
                  props.value.stato === "BOZZA"
                    ? theme.palette.disabled.main
                    : this.props.theme.palette.primary.main,
              }}
              onClick={(e) => {
                this.onCreaManualeDaClicked(props.value, e);
              }}
            >
              <PostAddIcon />
            </IconButton>
          ),
        },
        {
          id: "cancella",
          Header: this.state.showHiddenModels ? "Ripristina" : "Elimina",
          width: 80,
          filterable: false,
          accessor: (row) => row,
          Cell: (props) => (
            <IconButton
              style={{ color: this.props.theme.palette.primary.main }}
              size="small"
              onClick={(e) => {
                this.onCancellaOrRipristinaButtonClicked(props.value, e);
              }}
            >
              {this.state.showHiddenModels ? <RestoreIcon /> : <HighlightOff />}
            </IconButton>
          ),
        },
      ];
    }
    return [
      {
        Header: "Numero",
        id: "numero",
        filterable: false,
        accessor: (row) => row,
        width: 120,
        Cell: (props) =>
          this.state.loadedPage === 0
            ? "" + props.index
            : this.state.loadedPage + "" + props.index,
      },
      this.generateFilterColumn({
        id: "id",
        placeholder: "ID modello",
        filterValue: this.state.filters.idModello,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                idModello: value,
              },
            }));
          }
        },
      }),
      {
        Header: "ID modello base",
        id: "manualeBaseId",
        filterable: false,
        accessor: (d) => d.manualeBaseId,
        width: 154,
        Cell: (props) => (props.value === null ? " - " : props.value),
      },
      {
        Header: "Brand",
        id: "brand",
        filterable: false,
        accessor: (d) => d.brandId,
        width: 120,
        Cell: (props) => {
          let brand = "";
          for (let i = 0; i < this.state.brands.length; i++) {
            if (this.state.brands[i].id === props.value) {
              brand = this.state.brands[i].nome;
              break;
            }
          }
          return brand;
        },
      },
      this.generateFilterColumn({
        id: "titolo",
        placeholder: "Titolo",
        filterValue: this.state.filters.titolo,
        onChange: (value) => {
          this.setState((prevState) => ({
            filters: {
              ...prevState.filters,
              titolo: value,
            },
          }));
        },
      }),
      {
        Header: "Data aggiornamento",
        id: "dataUltimoAggiornamento",
        filterable: false,
        accessor: (d) => moment(d.dataUltimoAggiornamento).format(dateFormat),
        width: 160,
      },
      this.generateFilterColumn({
        id: "regolamenti",
        placeholder: "Regolamenti",
        filterValue: this.state.filters.regolamenti,
        onChange: (value) => {
          this.setState((prevState) => ({
            filters: {
              ...prevState.filters,
              regolamenti: value,
            },
          }));
        },
      }),
      {
        Header: "Stato pubblicazione",
        id: "stato",
        filterable: false,
        accessor: (d) => d.stato,
        minWidth: 180,
      },
      {
        id: "creamanuale",
        Header: "Crea manuale da",
        show: this.state.showHiddenModels ? false : true,
        width: 152,
        filterable: false,
        accessor: (row) => row,
        Cell: (props) => (
          <IconButton
            size="small"
            disabled={props.value.stato === "BOZZA" ? true : false}
            style={{
              color:
                props.value.stato === "BOZZA"
                  ? theme.palette.disabled.main
                  : this.props.theme.palette.primary.main,
            }}
            onClick={(e) => {
              this.onCreaManualeDaClicked(props.value, e);
            }}
          >
            <PostAddIcon />
          </IconButton>
        ),
      },
    ];
  };

  onlySpaces(str) {
    if (str !== null) {
      return str.trim().length === 0;
    }
    return "placeholder";
  }

  renderEditable = (cellInfo) => {
    if (
      this.state.edit === true &&
      this.state.modelloSelezionato.id === cellInfo.row.id
    ) {
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => {
            const modelli = [...this.state.modelli];
            //records[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            modelli[cellInfo.index][cellInfo.column.id] = e.target.textContent;
            this.setState({ modelli });
          }}
        >
          {this.onlySpaces(cellInfo.value) ? "placeholder" : cellInfo.value}
        </div>
      );
    }
    return <div>{cellInfo.value}</div>;
  };

  renderEditableStato = (cellInfo) => {
    if (
      this.state.edit === true &&
      this.state.modelloSelezionato.id === cellInfo.row.id
    ) {
      return (
        <FormControl>
          <Select value={cellInfo.value} name="activated">
            <MenuItem
              value={"PUBBLICATO"}
              onClick={() => this.onPublicationButtonClicked(cellInfo.original)}
            >
              PUBBLICATO
            </MenuItem>
            <MenuItem value={"BOZZA"}>BOZZA</MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return <div>{cellInfo.value}</div>;
    }
  };

  render() {
    const columns = this.getColumns();
    const isSuperconsulente =
      this.props.consulenteId !== null && this.props.superconsulenteView;
    const isAdmin = this.props.consulenteId === null;
    return (
      <div style={{ paddingTop: "30px", minHeight: "400px" }}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div style={styles.mainContainer}>
            {isAdmin ? (
              <SelezioneGruppo
                gruppoId={this.state.filters.idGruppo}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
              />
            ) : null}
            {isAdmin || isSuperconsulente ? (
              <div style={{ marginBottom: "30px" }}>
                <Select
                  value={this.state.showHiddenModels}
                  name="activated"
                  onChange={(e) => {
                    this.onHiddenManualiSelection(e);
                  }}
                >
                  <MenuItem value={false}>Modelli non cancellati</MenuItem>
                  <MenuItem value={true}>Modelli cancellati</MenuItem>
                </Select>
              </div>
            ) : null}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ActionButton
                label="CREA MODELLO PERSONALIZZATO"
                disabled={!isSuperconsulente && !isAdmin}
                onClick={this.onCreaModelloButtonClicked}
                grayVersion={false}
              />
            </div>
            <BasicTable
              filterable={false}
              resizable={true}
              sortable={false}
              defaultPageSize={this.state.pageSize}
              page={this.state.loadedPage}
              pages={this.state.totalPages}
              onPageChange={this.onPageChange}
              data={this.state.modelli}
              columns={columns}
            />
          </div>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <TwoActionsAlertDialog
          visible={this.state.twoActionsAlertVisible}
          title={this.state.modalTitle}
          message={this.state.modalMessage}
          onIndietroClicked={this.onPubblicationCancelled}
          onOkClicked={this.onAlertOkButtonPressed}
        />
        {/* {this.state.showModalSelezioneBrand ?
          <ModalSelezioneBrand
            brands={this.state.brands}
            open={this.state.showModalSelezioneBrand}
            modello={this.state.modelloSelezionato}
            isSuperconsulente={this.props.superconsulenteView}
            onSubmit={this.onBrandsSelected}
            onCancel={this.onBrandSelectionAborted}
          />
          : null
        } */}
        {this.state.showModalSelezioneGruppo && (
          <ModalSelezioneGruppo
            gruppoId={this.state.selectedGruppoId}
            gruppi={this.state.gruppi}
            open={this.state.showModalSelezioneGruppo}
            onCancel={this.onCancelGruppoSelection}
            onGruppoSelected={this.saveSelectedGruppoForModello}
            onCreaButtonClicked={this.onCreaButtonInModalClicked}
            origin={this.state.origin}
          />
        )}
        {this.state.showModalSelezioneConsulente && (
          <ModalSelezioneConsulente
            isModalOpen={this.state.showModalSelezioneConsulente}
            openEserciziModal={this.handleOpenEserciziModal}
            gruppoId={this.state.gruppoId}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.handleCloseConsulentiModal}
          />
        )}
        {this.state.showModalSelezioneEsercizio && (
          <ModalSelezioneEsercizioCommerciale
            idConsulente={this.state.consulenteId}
            gruppoId={this.state.gruppoId}
            manualeBaseId={this.state.modelloBaseId}
            isModalOpen={this.state.showModalSelezioneEsercizio}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.handleCloseEserciziModal}
            openModalitaModal={this.onOpenModalModalita}
          />
        )}
        {this.state.showModalSelezioneModalita && (
          <ModalSelezioneModalitaModello
            idConsulente={this.state.consulenteId}
            selectedEsercizio={this.state.esercizioId}
            gruppoId={this.state.selectedGruppoId}
            isModello={this.state.isModello}
            manualeBaseId={this.state.modelloBaseId}
            open={this.state.showModalSelezioneModalita}
            handleError={this.handleError}
            theme={this.props.theme}
            handleInvalidToken={this.handleInvalidToken}
            isChapterSelectionBlocked={this.state.isChapterSelectionBlocked}
            isControlliSelectionBlocked={this.state.isControlliSelectionBlocked}
            onClose={this.onCloseModalModalita}
            onProseguiClicked={this.executeCreationModelloPersonalizzato}
          />
        )}
        {this.state.showGoToPageModal && (
          <GoToPageModal
            open={this.state.showGoToPageModal}
            onCancel={this.onGoToPageCanceled}
            message={this.state.goToPageMessage}
            path={this.state.goToPagePath}
          />
        )}
        {this.state.loadingModal && (
          <LoadingModal
            open={this.state.loadingModal}
            testo={
              this.state.isModello
                ? "Generazione modello in corso..."
                : "Generazione manuale in corso..."
            }
          ></LoadingModal>
        )}
      </div>
    );
  }
}

ModelliPersonalizzatiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired,
};

export default withRouter(ModelliPersonalizzatiTable);
